import { ApiClientCRM } from 'app/service/api-service'
import { ReportGeneralFeedbackCustomerPoint } from './contant'
import { IQueryReportCustomerFeedback } from './modal'

export const requestReportGeneralFeedbackCustomer = (
  params: IQueryReportCustomerFeedback,
) =>
  ApiClientCRM.Get({
    url: ReportGeneralFeedbackCustomerPoint.GetByYear,
    params: { params },
  })

export const requestReportGeneralFeedbackCustomerMonth = (
  params: IQueryReportCustomerFeedback,
) =>
  ApiClientCRM.Get({
    url: ReportGeneralFeedbackCustomerPoint.GetByMonth,
    params: { params },
  })
