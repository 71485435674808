/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  DeleteFilled,
  DownloadOutlined,
  ExportOutlined,
  ImportOutlined,
} from '@ant-design/icons'
import R from 'app/assets/R'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseModal } from 'app/components/common/BaseModal'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseUpload } from 'app/components/common/BaseUpload'
import { isNull } from 'lodash'
import { EXAMPLE_URL_EXCEL_FILE, normFile } from 'parkway-web-common'
import React, { ReactNode, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as S from './styles'
import { BaseTooltip } from 'app/components/common/BaseTooltip'

interface Props {
  title?: string
  titleI18nKey?: string
  isExport?: boolean
  isImport?: boolean
  onPressExport?: () => Promise<void>
  onPressImport?: (file?: any) => void
  rightComponent?: ReactNode
  urlExample?: string
  nameFileExample?: string
  isShowTooltip?: boolean
  isLoadingExport?: boolean
}

const HeaderPage: React.FC<Props> = ({
  title,
  isExport,
  isImport,
  onPressExport,
  onPressImport,
  rightComponent,
  titleI18nKey,
  urlExample,
  isShowTooltip,
  isLoadingExport,
}) => {
  const { t } = useTranslation()
  const [openImportArea, setOpenImportArea] = useState(false)
  const [file, setFile] = useState<File | null>(null)

  const onCloseModal = () => {
    setOpenImportArea(false)
  }

  const onOpenModal = () => {
    setOpenImportArea(true)
  }

  const onRemoveFile = () => {
    setFile(null)
  }

  const onConfirmModal = () => {
    if (file) {
      onPressImport?.(file)
      onRemoveFile()
      onCloseModal()
    }
  }

  const onChangeFile = (values: { file: any }) => {
    setTimeout(() => {
      const { file } = values
      const { lastModified, name, type, originFileObj } = file
      const newFile = new File([originFileObj], name, { type, lastModified })
      setFile(newFile)
    }, 500)
  }

  const actionComponent = useCallback(() => {
    return (
      <BaseRow gutter={16} style={{ marginRight: 0 }}>
        {isExport && (
          <BaseCol>
            <ExportExcelButton
              isLoadingExport={isLoadingExport}
              onPressExport={onPressExport}
            />
          </BaseCol>
        )}
        {isImport && (
          <>
            <BaseCol>
              <a href={urlExample ?? EXAMPLE_URL_EXCEL_FILE}>
                <S.ButtonAction>
                  <BaseRow gutter={8}>
                    <BaseCol>
                      <DownloadOutlined rev={undefined} />
                    </BaseCol>
                    <BaseCol>
                      {t(R.strings.download_example_import_file)}
                    </BaseCol>
                  </BaseRow>
                </S.ButtonAction>
              </a>
            </BaseCol>
            <BaseCol>
              <S.ButtonAction onClick={onOpenModal}>
                <BaseRow gutter={8}>
                  <BaseCol>
                    <ImportOutlined rev={undefined} />
                  </BaseCol>
                  <BaseCol>{t(R.strings.import)}</BaseCol>
                </BaseRow>
              </S.ButtonAction>
            </BaseCol>
          </>
        )}
        {rightComponent && rightComponent}
      </BaseRow>
    )
  }, [
    isExport,
    isImport,
    onPressImport,
    onPressExport,
    onOpenModal,
    isLoadingExport,
  ])()

  return (
    <>
      <S.RootWrapper
        align={'middle'}
        justify={'space-between'}
        wrap={false}
        gutter={16}
      >
        <BaseCol xl={16}>
          {isShowTooltip ? (
            <BaseTooltip
              title={`${titleI18nKey ? t(titleI18nKey) : title ?? ''}`}
            >
              <S.HeadingTitlePage>
                {titleI18nKey ? t(titleI18nKey) : title ?? ''}
              </S.HeadingTitlePage>
            </BaseTooltip>
          ) : (
            <S.HeadingTitlePage>
              {titleI18nKey ? t(titleI18nKey) : title ?? ''}
            </S.HeadingTitlePage>
          )}
        </BaseCol>
        <BaseCol>{actionComponent}</BaseCol>
      </S.RootWrapper>
      {openImportArea && (
        <BaseModal
          title={t(R.strings.import_file)}
          open={openImportArea}
          onOk={onConfirmModal}
          okButtonProps={{ disabled: isNull(file) }}
          onCancel={onCloseModal}
          okText={t(R.strings.import)}
          cancelText={t(R.strings.cancel)}
        >
          <S.DraggerInput
            name="dragger"
            valuePropName="file"
            getValueFromEvent={normFile}
            noStyle
          >
            <BaseUpload.Dragger
              name="files"
              onChange={onChangeFile}
              customRequest={() => undefined}
              showUploadList={false}
            >
              <S.DraggerWrapper>
                <S.IconMailBox rev={undefined} size={24} />
              </S.DraggerWrapper>
            </BaseUpload.Dragger>
          </S.DraggerInput>
          {file && (
            <S.FileNameDisplayWrapper
              gutter={20}
              justify={'start'}
              align={'middle'}
            >
              <BaseCol>
                <span>{file?.name}</span>
              </BaseCol>
              <BaseCol>
                <BaseButton
                  icon={<DeleteFilled rev={undefined} onClick={onRemoveFile} />}
                />
              </BaseCol>
            </S.FileNameDisplayWrapper>
          )}
        </BaseModal>
      )}
    </>
  )
}

export default HeaderPage

export const ExportExcelButton = ({
  isLoadingExport,
  onPressExport,
}: {
  onPressExport?: () => Promise<void>
  isLoadingExport?: boolean
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const { t } = useTranslation()

  const _onPressExport = async () => {
    try {
      setIsLoading(true)
      await onPressExport?.()
    } catch (error) {
      console.log({ error })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <S.ButtonAction
      onClick={_onPressExport}
      loading={isLoadingExport || isLoading}
    >
      <BaseRow gutter={8}>
        <BaseCol>
          <ExportOutlined rev={undefined} />
        </BaseCol>
        <BaseCol>{t(R.strings.export_excel)}</BaseCol>
      </BaseRow>
    </S.ButtonAction>
  )
}

interface Props {
  onPressImport?: (file?: any) => void
  urlExample?: string
}

export const ImportButtonArea: React.FC<Props> = ({
  onPressImport,
  urlExample,
}) => {
  const { t } = useTranslation()
  const [openImportArea, setOpenImportArea] = useState(false)
  const [file, setFile] = useState<File | null>(null)

  const onCloseModal = () => {
    setOpenImportArea(false)
  }

  const onOpenModal = () => {
    setOpenImportArea(true)
  }

  const onRemoveFile = () => {
    setFile(null)
  }

  const onConfirmModal = () => {
    if (file) {
      onPressImport?.(file)
      onRemoveFile()
      onCloseModal()
    }
  }

  const onChangeFile = (values: { file: any }) => {
    setTimeout(() => {
      const { file } = values
      const { lastModified, name, type, originFileObj } = file
      const newFile = new File([originFileObj], name, { type, lastModified })
      setFile(newFile)
    }, 500)
  }

  return (
    <>
      <BaseRow gutter={10} style={{ marginRight: 0 }}>
        <BaseCol>
          <a href={urlExample ?? EXAMPLE_URL_EXCEL_FILE}>
            <S.ButtonAction>
              <BaseRow gutter={8}>
                <BaseCol>
                  <DownloadOutlined rev={undefined} />
                </BaseCol>
                <BaseCol>{t(R.strings.download_example_import_file)}</BaseCol>
              </BaseRow>
            </S.ButtonAction>
          </a>
        </BaseCol>
        <BaseCol>
          <S.ButtonAction onClick={onOpenModal}>
            <BaseRow gutter={8}>
              <BaseCol>
                <ImportOutlined rev={undefined} />
              </BaseCol>
              <BaseCol>{t(R.strings.import)}</BaseCol>
            </BaseRow>
          </S.ButtonAction>
        </BaseCol>
      </BaseRow>
      {openImportArea && (
        <BaseModal
          title={t(R.strings.import_file)}
          open={openImportArea}
          onOk={onConfirmModal}
          okButtonProps={{ disabled: isNull(file) }}
          onCancel={onCloseModal}
          okText={t(R.strings.import)}
          cancelText={t(R.strings.cancel)}
        >
          <S.DraggerInput
            name="dragger"
            valuePropName="file"
            getValueFromEvent={normFile}
            noStyle
          >
            <BaseUpload.Dragger
              name="files"
              onChange={onChangeFile}
              customRequest={() => undefined}
              showUploadList={false}
            >
              <S.DraggerWrapper>
                <S.IconMailBox rev={undefined} size={24} />
              </S.DraggerWrapper>
            </BaseUpload.Dragger>
          </S.DraggerInput>
          {file && (
            <S.FileNameDisplayWrapper
              gutter={20}
              justify={'start'}
              align={'middle'}
            >
              <BaseCol>
                <span>{file?.name}</span>
              </BaseCol>
              <BaseCol>
                <BaseButton
                  icon={<DeleteFilled rev={undefined} onClick={onRemoveFile} />}
                />
              </BaseCol>
            </S.FileNameDisplayWrapper>
          )}
        </BaseModal>
      )}
    </>
  )
}
