import {
  EFeedbackContentType,
  EFeedbackStatus,
  ICustomerFeedback,
  IQueryCustomerFeedback,
} from 'app/api/operation/general-feedback-customer/modal'
import { FunctionPermissionEnum } from 'app/common/permission-module'
import { useVerifyPermission } from 'app/common/use-verify-permission'
import { useDebounce } from 'app/hook'
import { useGetCustomerFeedbackList } from 'app/react-query/hook/general-feedback-customer'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHookIdentified } from './hook-identified'
import { useHookUnidentified } from './hook-unidentified'
import { EGroupFeedback, IFormFilter, IProps } from './type'
import { isEmpty, isEqual } from 'lodash'
import styled from 'styled-components'
import { BaseRow } from 'app/components/common/BaseRow'
import BaseText, { ColorTextType } from 'app/components/common/BaseText'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import {
  EFeedbackContentTypeMap,
  EFeedbackStatusMap,
  GeneralFeedbackCustomerMessageCode,
} from 'app/api/operation/general-feedback-customer/contant'
import { requestExportExcelGeneralFeedbackCustomer } from 'app/api/operation'
import { notificationController } from 'app/controllers/notification-controller'
import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { SvgStarWhiteIcon } from 'app/assets/svg-assets'
export const useTable = ({ module }: IProps) => {
  const { t } = useTranslation()
  const { isHavePermissionFunction, userData } = useVerifyPermission()

  const [filter, setFilter] = useState<IFormFilter>({
    groupFeedback: EGroupFeedback.IDENTIFIED,
  })

  const filterDebounce = useDebounce<IFormFilter>(filter, 500)

  const isHavAllPermission = useMemo(() => {
    return isHavePermissionFunction(
      module,
      FunctionPermissionEnum.OPERATION_READ_GENERAL_FEEDBACK_CUSTOMER_FOR_OPERATION,
    )
  }, [userData, module])

  const isHavePermissionExport = useMemo(() => {
    if (
      isHavePermissionFunction(
        module,
        FunctionPermissionEnum.OPERATION_EXPORT_GENERAL_FEEDBACK_CUSTOMER_FOR_OPERATION,
      )
    )
      return true

    return isHavePermissionFunction(
      module,
      FunctionPermissionEnum.OPERATION_EXPORT_GENERAL_FEEDBACK_CUSTOMER,
    )
  }, [userData, module])

  const dataIdentified = useHookIdentified({
    params: filterDebounce,
    isHavAllPermission,
  })

  const dataUnidentified = useHookUnidentified({
    params: filterDebounce,
  })

  const { columns, handleTableChange, pagination, setPagination } =
    useMemo(() => {
      if (filterDebounce?.groupFeedback === EGroupFeedback.IDENTIFIED) {
        return dataIdentified
      }

      return dataUnidentified
    }, [filterDebounce?.groupFeedback, dataIdentified, dataUnidentified])

  const params: IQueryCustomerFeedback = useMemo(() => {
    return {
      ...filterDebounce,
      page: pagination.current,
      limit: pagination.pageSize,
      pageSize: pagination.pageSize,
      fromDate: filterDebounce?.startDateDayJs?.toISOString(),
      toDate: filterDebounce?.endDateDayJs?.toISOString(),
      isIdentified: isEqual(
        filterDebounce?.groupFeedback,
        EGroupFeedback.IDENTIFIED,
      ),
      channels: filterDebounce?.channels,
      levelTypes: filterDebounce?.levelTypes,
    }
  }, [filterDebounce, pagination])

  const { data: dataInfinity, isLoading } = useGetCustomerFeedbackList(params)

  const dataFlatten = useMemo(() => {
    return dataInfinity
  }, [dataInfinity])

  const data = useMemo(() => {
    return dataFlatten?.data?.map((item, index) => ({
      ...item,
      key: index + 1,
    })) as ICustomerFeedback[]
  }, [dataFlatten])

  console.log({ dataInfinity })

  const handleExportExcel = async () => {
    try {
      const response = await requestExportExcelGeneralFeedbackCustomer({
        ...filterDebounce,
        fromDate: filterDebounce?.startDateDayJs?.toISOString(),
        toDate: filterDebounce?.endDateDayJs?.toISOString(),
        isIdentified: isEqual(
          filterDebounce?.groupFeedback,
          EGroupFeedback.IDENTIFIED,
        ),
        channels: filterDebounce?.channels,
        levelTypes: filterDebounce?.levelTypes,
      })
      if (
        isEqual(
          response?.msgcode,
          GeneralFeedbackCustomerMessageCode.successExportData,
        )
      ) {
        window.open(
          response?.data?.url ?? response?.data?.Url,
          '_blank',
          'noopener,noreferrer',
        )
        notificationController?.success({
          message: t(R.strings.export_excel_success),
        })
      }
    } catch (error) {
      notificationController?.error({
        message: t(R.strings.error),
        description: t(R.strings.with_error_please_contact_with_admin),
      })
    }
  }

  const handleChangeFilter = (newFilter: IFormFilter) => {
    setFilter(newFilter)
    setPagination({ ...pagination, current: 1 })
  }

  return {
    data,
    columns,
    filter,
    isLoading,
    handleTableChange,
    pagination: {
      ...pagination,
      total: dataInfinity?.total ?? 0,
    },
    handleChangeFilter,
    handleExportExcel,
    isHavePermissionExport,
    isHavAllPermission,
  }
}

export const geColorFeedback = (rate?: number, isText = true) => {
  switch (rate) {
    case 1:
      return {
        text: 'znsColorText1Star' as ColorTextType,
        background: 'znsColorText1Star' as ColorTextType,
      }
    case 2:
      return {
        text: 'znsColorText2Star' as ColorTextType,
        background: 'znsColorText2Star' as ColorTextType,
      }
    case 3:
      return {
        text: 'znsColorText3Star' as ColorTextType,
        background: 'znsColorText3Star' as ColorTextType,
      }
    case 4:
      if (isText) {
        return {
          text: 'primaryColor' as ColorTextType,
          background: 'znsColorText4Star' as ColorTextType,
        }
      }
      return {
        text: 'collapseBackgroundColor' as ColorTextType,
        background: 'znsColorText34Star' as ColorTextType,
      }
    case 5:
      if (isText) {
        return {
          text: 'primaryColor' as ColorTextType,
          background: 'znsColorText5Star' as ColorTextType,
        }
      }
      return {
        text: 'collapseBackgroundColor' as ColorTextType,
        background: 'znsColorText5Star' as ColorTextType,
      }
    default:
      return {
        text: 'primaryColor' as ColorTextType,
        background: 'backgroundColor' as ColorTextType,
      }
  }
}

export const getOpacityText = (rate?: number) => {
  switch (rate) {
    case 1:
    case 2:
    case 3:
      return '1'
    case 4:
    case 5:
      return '0.7'
    default:
      return '0.7'
  }
}

export const StarWrapper = styled(BaseRow)<{ $backgroundColor: string }>`
  background-color: ${pro => pro?.$backgroundColor};
  padding: 2px 4px;
  width: fit-content;
  border-radius: 100px;
`

export const DisplayNoteComp = ({
  note,
  rate,
}: {
  note: string
  rate?: number
}) => {
  const [isShow, setIsShow] = useState(false)

  const onPressShow = () => {
    setIsShow(!isShow)
  }

  const noteSlice = useMemo(() => {
    return note?.slice(0, 100)
  }, [note])

  return (
    <BaseText
      children={
        <span>
          {isShow ? note : noteSlice}
          {!isEmpty(note) && note?.length > 100 && !isShow ? '...' : ''}
          {!isEmpty(note) && note?.length > 100 ? (
            <span
              onClick={onPressShow}
              style={{
                color: convertedVariables.collapseBackgroundColor,
                cursor: 'pointer',
              }}
            >
              {isShow ? ' Thu gọn' : ' Xem thêm'}
            </span>
          ) : null}
        </span>
      }
      fontWeight="medium"
      textAlign="left"
      colorText={geColorFeedback(rate)?.text}
      opacity={getOpacityText(rate)}
    />
  )
}

// Componnet trạng thái xử lý
export const StatusProcessing = ({ status }: { status?: EFeedbackStatus }) => {
  return (
    <div
      style={{
        padding: '4px 8px',
        borderRadius: 20,
        background: (() => {
          switch (status) {
            case EFeedbackStatus.WAIT_PROGRESS:
              return 'linear-gradient(180deg, #FFAE49, #FF7617)'
            case EFeedbackStatus.IN_PROGRESS:
              return 'linear-gradient(180deg, #2F7EFF, #659FFF)'
            case EFeedbackStatus.PROCESSED:
              return 'linear-gradient(180deg, #629DAA, #6DAEBD 100%)'
          }
        })(),
        // center
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <BaseText
        children={status ? EFeedbackStatusMap?.[status]?.label : '-'}
        fontWeight="medium"
        textAlign="left"
        colorText="backgroundColor"
      />
    </div>
  )
}

// phân loại mức độ component
export const FeedbackTypeItem = ({ type }: { type: EFeedbackContentType }) => {
  return (
    <div
      style={{
        padding: 4,
        borderRadius: 20,
        backgroundColor: (() => {
          switch (type) {
            case EFeedbackContentType.EXPERTISE:
              return '#E0F2FE'
            case EFeedbackContentType.SERVICE:
              return '#FEF0C7'
            case EFeedbackContentType.FACILITY:
              return '#D1FADF'
          }
        })(),
      }}
    >
      <BaseText
        children={EFeedbackContentTypeMap?.[type]?.label ?? '-'}
        fontWeight="medium"
        textAlign="left"
        fontSize={'xxxs'}
        style={{
          color: (() => {
            switch (type) {
              case EFeedbackContentType.EXPERTISE:
                return '#065986'
              case EFeedbackContentType.SERVICE:
                return '#DC6803'
              case EFeedbackContentType.FACILITY:
                return '#039855'
            }
          })(),
        }}
      />
    </div>
  )
}

export const DynamicContentComp = ({
  rate,
  content,
}: {
  rate?: number
  content?: string
}) => {
  return (
    <BaseRow gutter={[8, 8]} align="top">
      {rate ? (
        <BaseCol>
          <StarWrapper
            $backgroundColor={
              convertedVariables[geColorFeedback(rate).background]
            }
            gutter={8}
            align={'middle'}
            justify={'center'}
          >
            <BaseCol>
              <BaseText
                children={rate}
                fontWeight="semibold"
                textAlign="center"
                colorText={'backgroundColor'}
              />
            </BaseCol>
            <SvgStarWhiteIcon />
          </StarWrapper>
        </BaseCol>
      ) : null}

      <BaseCol style={{ display: 'flex', flex: 1 }}>
        <DisplayNoteComp note={content ?? ''} rate={rate} />
      </BaseCol>
    </BaseRow>
  )
}
