import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import HeaderPage from 'app/components/header/components/HeaderPage'
import { BaseTableWrapper } from 'app/components/tables/BaseTableReport/styles'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { FONT_SIZE, FONT_WEIGHT } from 'parkway-web-common'
import styled from 'styled-components'

export const RootWrapper = styled.div`
  padding: 8px;
  padding-bottom: 2px;
`

export const HeadPageWrapper = styled(HeaderPage)`
  padding-bottom: 0px !important;
`

export const BaseTableReport = styled(BaseTableWrapper)`
  .ant-table-thead > tr > th {
    text-align: left !important;
    font-size: ${FONT_SIZE.xxs} !important;
    font-weight: ${FONT_WEIGHT.semibold} !important;
    color: rgba(0, 34, 68, 0.7) !important;
  }

  .ant-table-thead > tr {
  }

  .date-time-column {
    min-width: 125px !important;
  }

  .clinic-column {
    min-width: 153px !important;
  }

  .patient-name-column {
    min-width: 150px !important;
  }

  .channel-column {
    min-width: 80px !important;
  }

  .level-type-column {
    min-width: 80px !important;
  }

  .content-type-column {
    min-width: 130px !important;
  }

  .dynamic-content-column {
    min-width: 300px !important;
  }

  .note-column {
    min-width: 160px !important;
  }

  .department-column {
    min-width: 130px !important;
  }

  .resolve-method-column {
    min-width: 160px !important;
  }

  .resolve-at-column {
    min-width: 87px !important;
  }

  .result-column {
    min-width: 160px !important;
  }

  .resolve-duration-column {
    min-width: 87px !important;
  }

  .status-column {
    min-width: 100px !important;
  }

  .ant-table-tbody {
    .ant-table-cell {
      border-right: 0px !important;
      border-left: 0px !important;
    }
  }
`
export const DayText = styled(BaseText)`
  font-size: ${FONT_SIZE.xxs} !important;
  font-weight: ${FONT_WEIGHT.semibold} !important;
  color: ${convertedVariables.primaryColor};
  opacity: 0.4;
`
export const DayContainer = styled(BaseSpace)``
