/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AvatarProps } from 'antd'
import { IJobTitle } from 'app/api/jobtitle/model/job-title-management'
import {
  backgroundColorAvatarMap,
  colorAvatarMap,
} from 'app/components/common/BaseAvatar/contant'
import { BaseBadgeProps } from 'app/components/common/BaseBadge'
import { NotificationType } from 'app/components/common/BaseNotification'
import {
  getGetStartEndDateOfWeek,
  getStartEndDateOfMonth,
} from 'app/components/common/BaseWeekPicker'
import { IResponseFilter } from 'app/components/tables/BaseTableReport/FilterReport/type'
import { REGEX_REMOVE_SPECIAL_CHARACTERS } from 'app/constant'
import { isEqual } from 'lodash'
import {
  FORMAT_CENTER_YYYY_MM_DD,
  ResponseType,
  Severity,
  formatMoney,
  moment,
} from 'parkway-web-common'
import { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react'
import { InfiniteData } from 'react-query'

interface NotificationSeverity {
  id: number
  name: NotificationType
}

export const defineColorBySeverity = (
  severity: NotificationType | undefined,
  rgb = false,
): string => {
  const postfix = rgb ? 'rgb-color' : 'color'
  switch (severity) {
    case 'error':
    case 'warning':
    case 'success':
      return `var(--${severity}-${postfix})`
    case 'info':
    default:
      return `var(--primary-${postfix})`
  }
}

export const notificationsSeverities: NotificationSeverity[] = [
  { id: 0, name: 'info' },
  { id: 1, name: 'success' },
  { id: 2, name: 'warning' },
  { id: 3, name: 'error' },
  { id: 4, name: 'mention' },
]

export const normalizeProp = (
  prop: string | number | [number, number],
): string =>
  typeof prop === 'number'
    ? `${prop}px`
    : (Array.isArray(prop) && `${prop[0]}px ${prop[1]}px`) || prop.toString()

export const mapBadgeStatus = (status: BaseBadgeProps['status']): Severity => {
  if (!status || status === 'default' || status === 'processing') {
    return 'info'
  }

  return status
}

interface UseMountedReturnVal {
  isMounted: MutableRefObject<boolean>
}

export const useMounted = (): UseMountedReturnVal => {
  const isMounted = useRef(false)

  useEffect(() => {
    isMounted.current = true

    return () => {
      isMounted.current = false
    }
  }, [])

  return { isMounted }
}

export const usePagination = () => {
  const getNextPageParam = (lastPage: ResponseType<any>) => {
    if (
      (lastPage?.metadata?.currentPage || 0) <
      (lastPage?.metadata?.totalPage || 0)
    ) {
      return { page: lastPage?.metadata?.nextPage }
    }
    return undefined
  }

  function mapDataResponse<T>(data: InfiniteData<T>): T {
    return data?.pages?.[0]
  }

  const flattenData = (data?: InfiniteData<ResponseType<any[]>>) => {
    if (!data || !data?.pages) {
      return []
    }

    let arr: any[] = []

    data?.pages?.forEach(itm => {
      if (itm?.data && itm?.data?.length > 0) {
        arr = [...arr, ...(itm?.data ?? [])]
      }
    })
    return arr
  }

  const flattenDataList = (data?: InfiniteData<any>) => {
    if (!data) {
      return []
    }

    return data?.pages?.[0]
  }

  return {
    mapDataResponse,
    flattenData,
    flattenDataList,
    getNextPageParam,
  }
}

export const initFilterTable = (): IResponseFilter => {
  const minMaxWeed = getStartEndDateOfMonth({
    month: moment().month() + 1,
    year: moment().year(),
  })

  const { startOfWeek, endOfWeek } = getGetStartEndDateOfWeek(
    moment().format(FORMAT_CENTER_YYYY_MM_DD),
    minMaxWeed.minDayOfMonth,
    minMaxWeed.maxDayOfMonth,
  )

  return {
    year: `${moment().year()}`,
    month: `${moment().month() + 1}`,
    week: {
      startDate: startOfWeek,
      endDate: endOfWeek,
    },
  }
}

export function convertToTitleCase(input: string): string {
  return input.charAt(0).toUpperCase() + input.slice(1).toLowerCase()
}

export const mapColorWithFirstCharacter = (firstCharacter: string) => {
  const character = firstCharacter.toUpperCase()
  const backgroundColor = backgroundColorAvatarMap[character]
  const color = colorAvatarMap[character]
  return { backgroundColor, color }
}

export const useBaseImage = ({ alt, src }: AvatarProps) => {
  const firstCharacterOfName = useMemo(() => {
    if (src) return 'P'

    if (!alt) return 'P'

    const nameSplit = alt?.trim()?.split(' ') ?? []
    const name = nameSplit?.[0] ?? ''
    const res = name?.replace(REGEX_REMOVE_SPECIAL_CHARACTERS, '')

    return res?.charAt(0)
  }, [alt, src])

  const color = useMemo(() => {
    if (src) return ''
    return mapColorWithFirstCharacter(firstCharacterOfName).color
  }, [firstCharacterOfName, src])

  const backgroundColor = useMemo(() => {
    if (src) return ''
    return mapColorWithFirstCharacter(firstCharacterOfName).backgroundColor
  }, [firstCharacterOfName, src])

  return { color, backgroundColor, firstCharacterOfName }
}

export const getBase64 = (file: Blob): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file as Blob)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = error => reject(error)
  })

export function compareArrays(arr1: string[], arr2: string[]): boolean {
  if (arr1.length !== arr2.length) {
    return false
  }
  arr1 = arr1.sort()
  arr2 = arr2.sort()
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false
    }
  }
  return true
}

export function useDebounce<T>(value: T, delay = 500): T {
  const [debounceValue, setDebounceValue] = useState<T>(value)

  useEffect(() => {
    const handler = setTimeout(() => setDebounceValue(value), delay)

    return () => clearTimeout(handler)
  }, [value, delay])

  return debounceValue
}

export const formatVietnamesePhoneNumber = (phone: string): string => {
  // Chuẩn hóa số điện thoại: thay +84 bằng 0
  let normalizedPhone = phone.replace(/^(\+84)/, "0");

  // Đảm bảo chỉ chứa số
  normalizedPhone = normalizedPhone.replace(/\D/g, "");

  // Định dạng thành 4-3-3 (0965 259 441)
  return normalizedPhone.replace(/(\d{4})(\d{3})(\d{3})/, "$1 $2 $3");
};
export const useGetHeightTable = (headHeight: number, bottomHeight: number) => {
  const pageHeight = document.documentElement.scrollHeight
  return pageHeight - headHeight - bottomHeight
}

export function formatPhoneNumber(phoneNumber: string) {
  phoneNumber = `${Number(phoneNumber)}`
    .replace('+', '')
    ?.replaceAll(/\s+/g, '')
  if (phoneNumber?.slice(0, 2) === '84') {
    phoneNumber = phoneNumber?.slice(2)
  }
  phoneNumber = phoneNumber?.trim()
  const firstPart = phoneNumber.slice(0, 3)
  const secondPart = phoneNumber.slice(3, 6)
  const thirdPart = phoneNumber.slice(6)

  return `0${firstPart} ${secondPart} ${thirdPart}`
}

export const formatMoneyWithoutSpace = (
  value: string | number,
  symbol = 'đ',
  lang?: string,
  decimal?: number,
) => {
  return formatMoney(value, symbol, lang, decimal)?.replaceAll(/\s+/g, '')
}


export const generateJobTitle = (jobTitles: IJobTitle[]) => {
  let values: string[] = []

  jobTitles.forEach(itm => {
    if (!values?.find(val => isEqual(itm?.name, val))) {
      values = values.concat([itm?.name ?? ''])
    }
  })
  return values?.join(' & ')
}