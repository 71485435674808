import { BaseSpace } from 'app/components/common/BaseSpace'
import { SectionBase } from 'app/components/common/SectionBase'
import { useGetHeightTable } from 'app/hook'
import { useTable } from './hook'
import { FilterLayout } from './layouts'
import { BaseTableReport, RootWrapper } from './styles'
import { IProps } from './type'
import { PatientProvider } from 'app/context/PatientContext'
import { useTranslation } from 'react-i18next'
import { useVerifyPermissionAndRedirect } from 'app/common/use-verify-permission-redirect'
import { useEffect } from 'react'
import { FunctionPermissionEnum } from 'app/common/permission-module'
import R from 'app/assets/R'
import styled from 'styled-components'

export const GeneralFeedbackCustomerContainer = (props: IProps) => {
  const {
    filter,
    handleChangeFilter,
    data,
    columns,
    isLoading,
    handleTableChange,
    pagination,
    handleExportExcel,
    isHavePermissionExport,
    isHavAllPermission,
  } = useTable(props)

  const { t } = useTranslation()

  const { isHavePermissionFunctionAndGoBacks } =
    useVerifyPermissionAndRedirect()

  useEffect(() => {
    isHavePermissionFunctionAndGoBacks(
      props?.module,
      [
        FunctionPermissionEnum.OPERATION_READ_GENERAL_FEEDBACK_CUSTOMER,
        FunctionPermissionEnum.OPERATION_READ_GENERAL_FEEDBACK_CUSTOMER_FOR_OPERATION,
      ],
      R.strings.general_feedback_customer,
    )
  }, [t, props?.module])

  return (
    <PatientProvider>
      <RootWrapper>
        <SectionBase style={{ padding: 0, paddingBottom: 8 }}>
          <BaseSpace size={8}>
            <div style={{ padding: 8 }}>
              <FilterLayout
                formData={filter}
                onChange={handleChangeFilter}
                handleExportExcel={handleExportExcel}
                isHavePermissionExport={isHavePermissionExport}
                isShowGroupFeedback={isHavAllPermission}
              />
            </div>

            <BaseTableReport
              columns={columns}
              dataSource={data}
              pagination={pagination}
              loading={isLoading}
              onChange={handleTableChange}
              rowClassName="row-overwrite-style"
              scroll={{
                x: true,
                y: data?.length ? useGetHeightTable(228, 15) : undefined,
              }}
            />
          </BaseSpace>
        </SectionBase>
      </RootWrapper>
    </PatientProvider>
  )
}