import { withLoading } from 'app/hocs/with-loading.hoc'
import MarketingPage from 'app/page/menu/marketing'
import AdditionalBillDiscountCampaignPage from 'app/page/menu/marketing/additional-bill-discount-campaign'
import AdditionalServiceDiscountCampaignPage from 'app/page/menu/marketing/additional-service-discount-campaign'
import AdditionalServiceDiscountVoucherPage from 'app/page/menu/marketing/additional-service-discount-voucher'
import CloneDiscountCampaignPage from 'app/page/menu/marketing/clone-discount-campaign'
import CreateDiscountCampaignPage from 'app/page/menu/marketing/create-discount-campaign'
import CreateVoucherPage from 'app/page/menu/marketing/create-voucher'
import DetailCampaignPage from 'app/page/menu/marketing/detail-discount-campaign'
import DetailVoucherPage from 'app/page/menu/marketing/detail-voucher'
import DiscountCampaignManagementPage from 'app/page/menu/marketing/discount-campaign-management'
import FindCampaignPage from 'app/page/menu/marketing/find-campaign-by-code'
import ImportMarketingPlanPage from 'app/page/menu/marketing/import-marketing-plan'
import LimitDiscountManagementPage from 'app/page/menu/marketing/limit-discount'
import ListReferralVoucherCodePage from 'app/page/menu/marketing/list-referral-voucher-code'
import ListVoucherCodePage from 'app/page/menu/marketing/list-voucher-code'
import RefererReportPage from 'app/page/menu/marketing/referer-report'
import ReferralVoucherUserStatisticsPage from 'app/page/menu/marketing/referral-voucher-user-statistics'
import UpdateDiscountCampaignPage from 'app/page/menu/marketing/update-discount-campaign'
import VoucherManagementPage from 'app/page/menu/marketing/voucher-management'
import Report001Page from 'app/page/menu/report/report-001'
import Report006Page from 'app/page/menu/report/report-006'
import Report007Page from 'app/page/menu/report/report-007'
import Report008Page from 'app/page/menu/report/report-008'
import Report009Page from 'app/page/menu/report/report-009'
import Report010Page from 'app/page/menu/report/report-010'
import Report011Page from 'app/page/menu/report/report-011'
import { Route } from 'react-router-dom'
import {
  LIMIT_DISCOUNT_MANAGEMENT_PATH,
  MARKETING_ADDITIONAL_BILL_DISCOUNT_CAMPAIGN_PATH,
  MARKETING_ADDITIONAL_SERVICE_CAMPAIGN_PATH,
  MARKETING_ADDITIONAL_SERVICE_VOUCHER_PATH,
  MARKETING_CAMPAIGN_PATH,
  MARKETING_CLONE_CAMPAIGN_PATH,
  MARKETING_CREATE_CAMPAIGN_PATH,
  MARKETING_CREATE_VOUCHER_PATH,
  MARKETING_DETAIL_CAMPAIGN_PATH,
  MARKETING_DETAIL_VOUCHER_PATH,
  MARKETING_FIND_CAMPAIGN_WITH_CODE,
  MARKETING_IMPORT_PLAN,
  MARKETING_LIST_REFERRAL_CODE_PATH,
  MARKETING_LIST_VOUCHER_CODE_PATH,
  MARKETING_PATH,
  MARKETING_REFERER_REPORT_PATH,
  MARKETING_REFERRAL_VOUCHER_USER_STATISTICS,
  MARKETING_REPORT_001_FULL_PATH,
  MARKETING_REPORT_006_FULL_PATH,
  MARKETING_REPORT_007_FULL_PATH,
  MARKETING_REPORT_008_FULL_PATH,
  MARKETING_REPORT_009_FULL_PATH,
  MARKETING_REPORT_010_FULL_PATH,
  MARKETING_REPORT_011_FULL_PATH,
  MARKETING_UPDATE_CAMPAIGN_PATH,
  MARKETING_VOUCHER_PATH,
  MARKETING_PRICING_MANAGEMENT_PATH,
  MARKETING_PRICING_MANAGEMENT_CREATE_ITEM_PRICE_PATH,
  MARKETING_PRICING_MANAGEMENT_DETAIL_ITEM_PRICE_PATH,
  MARKETING_PRICING_MANAGEMENT_UPDATE_ITEM_PRICE_PATH,
} from './route-path'
import PricingManagementPage from 'app/page/menu/marketing/pricing-management'
import CreateItemPricePage from 'app/page/menu/marketing/create-item-price'
import DetailItemPricePage from 'app/page/menu/marketing/detail-item-price'
import UpdateItemPricePage from 'app/page/menu/marketing/update-item-price'

const Marketing = withLoading(MarketingPage)
const ImportMarketingPlan = withLoading(ImportMarketingPlanPage)

const MarketingReport001 = withLoading(Report001Page)
const MarketingReport006 = withLoading(Report006Page)
const MarketingReport007 = withLoading(Report007Page)
const MarketingReport008 = withLoading(Report008Page)
const MarketingReport009 = withLoading(Report009Page)
const MarketingReport010 = withLoading(Report010Page)
const MarketingReport011 = withLoading(Report011Page)
const RefererReport = withLoading(RefererReportPage)

const MarketingDiscountCampaignManagement = withLoading(
  DiscountCampaignManagementPage,
)
const MarketingCreateDiscountCampaign = withLoading(CreateDiscountCampaignPage)
const MarketingUpdateDiscountCampaign = withLoading(UpdateDiscountCampaignPage)
const MarketingCloneDiscountCampaign = withLoading(CloneDiscountCampaignPage)
const MarketingDetailCampaign = withLoading(DetailCampaignPage)
const MarketingAdditionalServiceDiscountCampaign = withLoading(
  AdditionalServiceDiscountCampaignPage,
)
const MarketingAdditionalBillDiscountCampaign = withLoading(
  AdditionalBillDiscountCampaignPage,
)
const MarketingAdditionalServiceVoucher = withLoading(
  AdditionalServiceDiscountVoucherPage,
)

const MarketingVoucherManagement = withLoading(VoucherManagementPage)
const MarketingCreateVoucher = withLoading(CreateVoucherPage)
const MarketingDetailVoucher = withLoading(DetailVoucherPage)

const MarketingListVoucherCode = withLoading(ListVoucherCodePage)
const LimitDiscountManagement = withLoading(LimitDiscountManagementPage)
const MarketingListReferralVoucherCode = withLoading(
  ListReferralVoucherCodePage,
)

const MarketingFindCampaignWithCode = withLoading(FindCampaignPage)

const MarketingReferralVoucherUserStatistics = withLoading(
  ReferralVoucherUserStatisticsPage,
)

const PricingManagement = withLoading(PricingManagementPage)
const PriceManagementCreate = withLoading(CreateItemPricePage)
const PriceManagementDetail = withLoading(DetailItemPricePage)
const PriceManagementUpdate = withLoading(UpdateItemPricePage)

export const MarketingRoute = () => {
  return (
    <>
      <Route path={MARKETING_PATH} element={<Marketing />} />
      <Route path={MARKETING_IMPORT_PLAN} element={<ImportMarketingPlan />} />
      <Route
        path={MARKETING_REPORT_001_FULL_PATH}
        element={<MarketingReport001 />}
      />
      <Route
        path={MARKETING_REPORT_006_FULL_PATH}
        element={<MarketingReport006 />}
      />
      <Route
        path={MARKETING_REPORT_007_FULL_PATH}
        element={<MarketingReport007 />}
      />
      <Route
        path={MARKETING_REPORT_008_FULL_PATH}
        element={<MarketingReport008 />}
      />
      <Route
        path={MARKETING_REPORT_009_FULL_PATH}
        element={<MarketingReport009 />}
      />
      <Route
        path={MARKETING_REPORT_010_FULL_PATH}
        element={<MarketingReport010 />}
      />
      <Route
        path={MARKETING_REPORT_011_FULL_PATH}
        element={<MarketingReport011 />}
      />
      <Route
        path={MARKETING_CAMPAIGN_PATH}
        element={<MarketingDiscountCampaignManagement />}
      />
      <Route
        path={MARKETING_CREATE_CAMPAIGN_PATH}
        element={<MarketingCreateDiscountCampaign />}
      />
      <Route
        path={MARKETING_ADDITIONAL_SERVICE_CAMPAIGN_PATH}
        element={<MarketingAdditionalServiceDiscountCampaign />}
      />
      <Route
        path={MARKETING_ADDITIONAL_SERVICE_VOUCHER_PATH}
        element={<MarketingAdditionalServiceVoucher />}
      />
      <Route
        path={MARKETING_UPDATE_CAMPAIGN_PATH}
        element={<MarketingUpdateDiscountCampaign />}
      />
      <Route
        path={MARKETING_CLONE_CAMPAIGN_PATH}
        element={<MarketingCloneDiscountCampaign />}
      />
      <Route
        path={MARKETING_DETAIL_CAMPAIGN_PATH}
        element={<MarketingDetailCampaign />}
      />
      <Route
        path={MARKETING_VOUCHER_PATH}
        element={<MarketingVoucherManagement />}
      />
      <Route
        path={MARKETING_CREATE_VOUCHER_PATH}
        element={<MarketingCreateVoucher />}
      />
      <Route
        path={MARKETING_DETAIL_VOUCHER_PATH}
        element={<MarketingDetailVoucher />}
      />
      <Route path={MARKETING_REFERER_REPORT_PATH} element={<RefererReport />} />
      <Route
        path={MARKETING_LIST_VOUCHER_CODE_PATH}
        element={<MarketingListVoucherCode />}
      />
      <Route
        path={LIMIT_DISCOUNT_MANAGEMENT_PATH}
        element={<LimitDiscountManagement />}
      />
      <Route
        path={MARKETING_LIST_REFERRAL_CODE_PATH}
        element={<MarketingListReferralVoucherCode />}
      />
      <Route
        path={MARKETING_ADDITIONAL_BILL_DISCOUNT_CAMPAIGN_PATH}
        element={<MarketingAdditionalBillDiscountCampaign />}
      />
      <Route
        path={MARKETING_REFERRAL_VOUCHER_USER_STATISTICS}
        element={<MarketingReferralVoucherUserStatistics />}
      />
      <Route
        path={MARKETING_FIND_CAMPAIGN_WITH_CODE}
        element={<MarketingFindCampaignWithCode />}
      />
      <Route
        path={MARKETING_PRICING_MANAGEMENT_PATH}
        element={<PricingManagement />}
      />
      <Route
        path={MARKETING_PRICING_MANAGEMENT_CREATE_ITEM_PRICE_PATH}
        element={<PriceManagementCreate />}
      />
      <Route
        path={MARKETING_PRICING_MANAGEMENT_DETAIL_ITEM_PRICE_PATH}
        element={<PriceManagementDetail />}
      />
      <Route
        path={MARKETING_PRICING_MANAGEMENT_UPDATE_ITEM_PRICE_PATH}
        element={<PriceManagementUpdate />}
      />
    </>
  )
}
