/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  requestCreateGeneralFeedbackCustomer,
  requestGeneralFeedbackCustomer,
  requestResolveGeneralFeedbackCustomer,
  requestUpdateGeneralFeedbackCustomer,
} from 'app/api/operation'
import { GeneralFeedbackCustomerMessageCode } from 'app/api/operation/general-feedback-customer/contant'
import {
  ICreateCustomerFeedback,
  ICustomerFeedback,
  IQueryCustomerFeedback,
  IResolveCustomerFeedback,
  IUpdateCustomerFeedback,
} from 'app/api/operation/general-feedback-customer/modal'
import { ResponseTypeV2 } from 'app/common/model'
import { getToken } from 'app/service/storage/storage-service'
import { isEqual } from 'lodash'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { IResponseQueryList } from '../model/common'
import { GeneralFeedbackCustomerKeys } from '../query-key/general-feedback-customer'

export function useGetCustomerFeedbackList(
  params: IQueryCustomerFeedback,
  enabled = true,
) {
  const token = getToken()
  return useQuery<IResponseQueryList<ICustomerFeedback[]>, undefined>(
    GeneralFeedbackCustomerKeys.getGeneralFeedbackCustomers(params),
    async () => {
      try {
        const res: ResponseTypeV2<ICustomerFeedback[]> =
          await requestGeneralFeedbackCustomer(params)

        if (
          isEqual(
            res?.msgcode,
            GeneralFeedbackCustomerMessageCode.successGetData,
          )
        ) {
          return {
            data: res?.data ?? [],
            total: res.metadata?.totalItem ?? res?.data?.length ?? 0,
          }
        }
        return {
          data: [],
          total: 0,
        }
      } catch (error: any) {
        return {
          data: [],
          total: 0,
        }
      }
    },
    {
      enabled: !!token && enabled,
    },
  )
}

export const useCreateCustomerFeedback = () => {
  const queryClient = useQueryClient()
  return useMutation<unknown, unknown, ICreateCustomerFeedback, unknown>(
    async (body?: ICreateCustomerFeedback) => {
      return await requestCreateGeneralFeedbackCustomer(body)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: GeneralFeedbackCustomerKeys.allGeneralFeedbackCustomer,
        })
      },
    },
  )
}

export const useUpdateCustomerFeedback = () => {
  const queryClient = useQueryClient()
  return useMutation<
    unknown,
    unknown,
    { id: string; payload: IUpdateCustomerFeedback },
    unknown
  >(
    async ({
      id,
      payload,
    }: {
      id: string
      payload: IUpdateCustomerFeedback
    }) => {
      return await requestUpdateGeneralFeedbackCustomer(id, payload)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: GeneralFeedbackCustomerKeys.allGeneralFeedbackCustomer,
        })
      },
    },
  )
}

export const useResolveCustomerFeedback = () => {
  const queryClient = useQueryClient()
  return useMutation<
    unknown,
    unknown,
    { id: string; payload: IResolveCustomerFeedback },
    unknown
  >(
    async ({
      id,
      payload,
    }: {
      id: string
      payload: IResolveCustomerFeedback
    }) => {
      return await requestResolveGeneralFeedbackCustomer(id, payload)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: GeneralFeedbackCustomerKeys.allGeneralFeedbackCustomer,
        })
      },
    },
  )
}
