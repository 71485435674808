/* eslint-disable @typescript-eslint/no-explicit-any */
import { ExportOutlined } from '@ant-design/icons'
import R from 'app/assets/R'
import { StatusEnum } from 'app/common/enum'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseDatePicker } from 'app/components/common/pickers/BaseDatePicker'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { usePagination } from 'app/hook'
import { IClinic, IUnit } from 'app/model/unit.model'
import { useGetAreaList } from 'app/react-query/hook/location'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { Dayjs } from 'dayjs'
import { t } from 'i18next'
import { FONT_SIZE, FONT_WEIGHT } from 'parkway-web-common'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import { EGroupFeedback, IFormFilter } from '../../type'
import { ExportExcelButton } from 'app/components/header/components/HeaderPage'
import { AdvanceFilterLayout } from './AdvanceFilter'

interface IProps {
  onChange?: (value: IFormFilter) => void
  formData: IFormFilter
  handleExportExcel: () => Promise<void>
  isHavePermissionExport: boolean
  isShowGroupFeedback?: boolean
}

export const FilterLayout: React.FC<IProps> = ({
  formData,
  onChange,
  isHavePermissionExport,
  handleExportExcel,
  isShowGroupFeedback,
}) => {
  const { flattenDataList } = usePagination()

  const { data: areaApi, isLoading: isLoadingArea } = useGetAreaList({
    typeid: 'area',
    status: StatusEnum.ACTIVE,
  })

  const areaList = useMemo(() => {
    const res: IUnit[] = flattenDataList(areaApi)

    let arr: IClinic[] = []
    res?.forEach(i => {
      arr = [...arr, ...(i?.childs || [])]
    })

    return arr?.map(e => ({ ...e, _id: e?._oldId ?? e?._id }))
  }, [areaApi])

  const onChangeClinic = (locationIds: string[]) => {
    onChange && onChange({ ...formData, locationIds })
  }

  const onChangeStart = (startDateDayJs: Dayjs | null) => {
    onChange && onChange({ ...formData, startDateDayJs })
  }

  const onChangeGroupFeedback = (groupFeedback: EGroupFeedback) => {
    onChange && onChange({ ...formData, groupFeedback })
  }

  return (
    <BaseForm>
      <RootWrapper gutter={[8, 8]} align={'bottom'}>
        <BaseCol xl={18}>
          <BaseRow gutter={[8, 8]} align={'middle'}>
            {isShowGroupFeedback ? (
              <BaseCol xl={8}>
                <BaseFormItem hiddenLabel>
                  <BaseSelectWrapper
                    $prefix="Nhóm feedback"
                    $prefixWidth={120}
                    options={[
                      {
                        label: 'Đã định danh',
                        value: EGroupFeedback.IDENTIFIED,
                      },
                      {
                        label: 'Chưa định danh',
                        value: EGroupFeedback.UNIDENTIFIED,
                      },
                    ]}
                    value={formData?.groupFeedback}
                    onChange={e => onChangeGroupFeedback(e as EGroupFeedback)}
                  />
                </BaseFormItem>
              </BaseCol>
            ) : null}

            <BaseCol xl={9}>
              <BaseFormItem hiddenLabel>
                <BaseDatePickerStyled
                  $prefix="Ngày tiếp nhận feedback"
                  $prefixWidth={490}
                  format={'DD-MM-YYYY'}
                  placeholder={'Chọn'}
                  onChange={onChangeStart}
                  showTime={false}
                />
              </BaseFormItem>
            </BaseCol>

            <BaseCol xl={7}>
              <BaseFormItem hiddenLabel>
                <BaseSelectWrapper
                  showSearch
                  placeholder={t(R.strings.choose_clinic)}
                  allowClear
                  onChange={locations =>
                    onChangeClinic?.(locations as string[])
                  }
                  options={areaList?.map(item => ({
                    value: item.oldid,
                    label: item.name,
                  }))}
                  loading={isLoadingArea}
                  mode="multiple"
                  maxTagCount={1}
                  $prefix="Phòng khám"
                  $prefixWidth={88}
                />
              </BaseFormItem>
            </BaseCol>
          </BaseRow>
        </BaseCol>

        <BaseCol xl={6}>
          <BaseRow justify={'end'} align={'middle'} gutter={6}>
            <BaseCol>
              <AdvanceFilterLayout
                filter={formData}
                onChangeFilter={onChange}
              />
            </BaseCol>

            {isHavePermissionExport ? (
              <BaseCol>
                <BaseRow justify={'end'}>
                  <BaseFormItem hiddenLabel>
                    <ExportExcelButton onPressExport={handleExportExcel} />
                  </BaseFormItem>
                </BaseRow>
              </BaseCol>
            ) : null}
          </BaseRow>
        </BaseCol>
      </RootWrapper>
    </BaseForm>
  )
}

const RootWrapper = styled(BaseRow)``

const BaseSelectWrapper = styled(BaseSelect)<{
  $prefix?: string
  $prefixWidth?: number
}>`
  width: 100%;
  border: none !important;
  min-width: 100px !important;

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    box-shadow: none !important;
  }
  .ant-select-selector::before {
    font-size: ${FONT_SIZE.xs};
    font-weight: ${FONT_WEIGHT.medium};
    color: ${convertedVariables.primaryColor};
    opacity: 0.4;
    align-items: center;
    display: flex;
    content: '${props => props?.$prefix ?? '-'}:';
    width: ${props => props?.$prefixWidth ?? 40}px;
  }

  .ant-select-selection-placeholder {
    padding-left: ${props =>
      props.$prefixWidth ? `${props?.$prefixWidth}px` : 0};
  }
`
const BaseDatePickerStyled = styled(BaseDatePicker)`
  width: 100%;
  border: 1px solid #f0f1f3 !important;

  .ant-picker-input::before {
    content: '${props => props?.$prefix ?? '-'}:';
    width: ${props => props?.$prefixWidth ?? 40}px;
    font-size: ${FONT_SIZE.xs};
    font-weight: ${FONT_WEIGHT.medium};
    color: ${convertedVariables.primaryColor};
    opacity: 0.4;
  }
`

const BaseFormItem = styled(BaseForm.Item)`
  margin-bottom: 0px !important;
`
