import { BaseLayoutReport } from 'app/components/common/BaseLayoutReport'
import { data } from './data'
import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'
import { useVerifyPermissionAndRedirect } from 'app/common/use-verify-permission-redirect'
import { useEffect } from 'react'
import { ModulePermissionEnum } from 'app/common/permission-module'

const OperationContainer = () => {
  const { t } = useTranslation()

  const { isHavePermissionAndGoBack } = useVerifyPermissionAndRedirect()

  useEffect(() => {
    isHavePermissionAndGoBack(
      ModulePermissionEnum.OPERATION,
      R.strings.operation,
    )
  }, [t])
  
  return (
    <BaseLayoutReport
      data={data}
      titleI18nKey={R.strings.operation}
      modulePermission={ModulePermissionEnum.OPERATION}
    />
  )
}

export default OperationContainer
