export enum EFeedbackChannel {
  HOTLINE = 'HOTLINE',
  FACEBOOK = 'FACEBOOK',
  ZALO = 'ZALO',
  GMAIL = 'GMAIL',
  ZNS = 'ZNS',
  GOOGLE_BUSINESS = 'GOOGLE_BUSINESS',
  INTERNAL = 'INTERNAL',
  OTHER = 'OTHER',
}

export enum EFeedbackLevelType {
  SUGGESTION = 'SUGGESTION', // Góp ý
  SPECIAL_REQUEST = 'SPECIAL_REQUEST', // Yêu cầu biệt đãi
  COMPLAINT = 'COMPLAINT', // Khiếu nại
  GRIEVANCE = 'GRIEVANCE', // Phàn nàn
}

export enum EFeedbackContentType {
  EXPERTISE = 'EXPERTISE', // Chuyên môn
  SERVICE = 'SERVICE', // Dịch vụ
  FACILITY = 'FACILITY', // Cơ sở vật chất
}

export enum EFeedbackDepartment {
  NURSE = 'NURSE', // Y tá
  DOCTOR = 'DOCTOR', // Bác sĩ
  CONSULTANT = 'CONSULTANT', // Tư vấn
  SECURITY = 'SECURITY', // Bảo vệ
  MARKETING = 'MARKETING', // Marketing
  PROJECT = 'PROJECT', // Dự án
  PROCUREMENT = 'PROCUREMENT', // Mua hàng
  HR = 'HR', // Nhân sự
  OPERATIONS = 'OPERATIONS', // Vận hành
  LEGAL = 'LEGAL', // Pháp chế
  OTHER = 'OTHER', // Khác
}

export enum EFeedbackStatus {
  WAIT_PROGRESS = 'WAIT_PROGRESS', // Chưa xử lý
  IN_PROGRESS = 'IN_PROGRESS', // Đang xử lý
  PROCESSED = 'PROCESSED', // Đã xử lý
}

export interface ICreateCustomerFeedback {
  patientId?: string
  locationId?: string
  channel?: EFeedbackChannel
  levelTypes?: EFeedbackLevelType[]
  contentTypes?: EFeedbackContentType[]
  departments?: EFeedbackDepartment[]
  fixedContent?: string
  dynamicContent?: string
  note?: string
  attachments?: string[]
  receptionAt?: Date
  receptionBy?: string
}

export interface IUpdateCustomerFeedback extends IResolveCustomerFeedback{
  patientId?: string
  locationId?: string
  channel?: EFeedbackChannel
  levelTypes?: EFeedbackLevelType[]
  contentTypes?: EFeedbackContentType[]
  departments?: EFeedbackDepartment[]
  dynamicContent?: string
  note?: string
  attachments?: string[]
  receptionAt?: Date
  receptionBy?: string
}

export interface IResolveCustomerFeedback {
  resolveMethod?: string
  result?: string
  resolveAt?: string
  status?: EFeedbackStatus
  departments?: EFeedbackDepartment[]
}

export interface IQueryCustomerFeedback {
  keyword?: string
  patientId?: string
  locationIds?: string[]
  channels?: EFeedbackChannel[]
  levelTypes?: EFeedbackLevelType[]
  contentTypes?: EFeedbackContentType[]
  departments?: EFeedbackDepartment[]
  statuses?: EFeedbackStatus[]
  receptionBy?: string
  page?: number
  pageSize?: number
  fromDate?: string
  toDate?: string

  isIdentified?: boolean
}

export interface ICustomerFeedback {
  _id?: string
  patientId?: string
  locationId?: string
  channel?: EFeedbackChannel
  levelTypes?: EFeedbackLevelType[]
  contentTypes?: EFeedbackContentType[]
  departments?: EFeedbackDepartment[]
  fixedContent?: string
  dynamicContent?: string
  note?: string
  attachments?: string[]
  receptionAt?: Date
  receptionBy?: string
  resolveMethod?: string
  resolveAt?: Date
  resolveDuration?: number
  result?: string
  status?: EFeedbackStatus
  isDeleted?: boolean
  createdAt?: Date
  createdBy?: string
  updatedAt?: Date
  updatedBy?: string
  deletedAt?: Date
  deletedBy?: string
  receptionByName?: string
  resolveByName?: string
  createdByName?: string
  updatedByName?: string
  deletedByName?: string
  locationName?: string
  patientName?: string

  rate?: number
}

export interface ICustomerResponse extends ICustomerFeedback {
  patientCode: string
  patientName: string
  locationId: string
  locationName: string
}
