import { requestReportGeneralFeedbackCustomerMonth } from 'app/api/operation/report-general-feedback-customer'
import { ReportGeneralFeedbackCustomerMessageCode } from 'app/api/operation/report-general-feedback-customer/contant'
import {
  IRecordReportGeneralFeedbackCustomer
} from 'app/api/operation/report-general-feedback-customer/modal'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import BaseText from 'app/components/common/BaseText'
import { Loading } from 'app/components/common/Loading'
import { BaseChart } from 'app/components/common/charts/BaseChart'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { IUnit } from 'app/model/unit.model'
import { EChartsOption } from 'echarts-for-react'
import { isEqual } from 'lodash'
import {
  FONT_SIZE,
  FONT_WEIGHT,
  ResponseType,
  colors,
  formatMoney,
  moment,
} from 'parkway-web-common'
import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { useReportGeneralFeedbackCustomerContext } from '../../../context'

export interface ISeriesData {
  color?: string
  seriesName?: string
  value?: string
}

export interface IDataChart {
  type?: string[]
  // tháng này
  thisMonth?: number[]
  // tháng trước
  lastMonth?: number[]
}

interface IProps {
  unit?: IUnit
  clinics?: IUnit[]
  isLoadingClinics?: boolean
}

export const StackedBarChart: React.FC<IProps> = ({
  unit,
  clinics,
  isLoadingClinics,
}) => {
  const [isLoadingData, setIsLoadingData] = useState(false)

  const [selectedClinics, setSelectedClinics] = useState<string[]>([])

  const [dataChart, setDataChart] = useState<IDataChart>({
    type: [],
    thisMonth: [],
    lastMonth: [],
  })

  const maxValue = useMemo(() => {
    return Math.max(
      ...(dataChart?.thisMonth ?? []),
      ...(dataChart?.lastMonth ?? []),
    )
  }, [dataChart])

  const { filterResponse } = useReportGeneralFeedbackCustomerContext()

  const getData = async () => {
    if (!filterResponse?.month || !filterResponse?.year) return
    try {
      setIsLoadingData(true)
      const response: ResponseType<IRecordReportGeneralFeedbackCustomer[]> =
        await requestReportGeneralFeedbackCustomerMonth({
          areaId: unit?._id,
          locationIds: selectedClinics,
          month: `${filterResponse?.year}-${filterResponse?.month}`,
        })
      if (
        isEqual(
          response.msgcode,
          ReportGeneralFeedbackCustomerMessageCode.successGetData,
        )
      ) {
        const data = response.data
        const currentMonthString = `${filterResponse?.month}/01/${filterResponse?.year}`
        const lastMonthString = `${Number(filterResponse?.month ?? 1) - 1}/01/${
          filterResponse?.year
        }`

        const currentMonthMoment = moment(currentMonthString).format('MM/YYYY')
        const lastMonthMoment = moment(lastMonthString).format('MM/YYYY')

        const type = ['Feedback', 'Complaint', 'Đã giải quyết']
        const currentMonthData = data?.find(
          item => item?.time === currentMonthMoment,
        )
        const lastMonthData = data?.find(item => item?.time === lastMonthMoment)

        const thisMonth = [
          currentMonthData?.feedback ?? 0,
          currentMonthData?.complaint ?? 0,
          currentMonthData?.resolved ?? 0,
        ]
        const lastMonth = [
          lastMonthData?.feedback ?? 0,
          lastMonthData?.complaint ?? 0,
          lastMonthData?.resolved ?? 0,
        ]
        setDataChart({
          type,
          thisMonth,
          lastMonth,
        })
      }
      setIsLoadingData(false)
    } catch (error) {
      setIsLoadingData(false)
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      getData()

      return () => {
        setDataChart({})
      }
    }, 500)

    return () => clearTimeout(timer)
  }, [filterResponse?.month, filterResponse?.year, selectedClinics, unit?._id])

  const data = useMemo(() => {
    return {
      categories: dataChart.type,
      series: [
        {
          name: 'Tháng liền trước',
          data: dataChart.lastMonth,
          color: '#7CD4FD',
        },
        {
          name: 'Tháng này',
          data: dataChart.thisMonth,
          color: '#A6F4C5',
        },
      ],
    }
  }, [dataChart])

  const itemToolTip = (item: ISeriesData) => {
    const containerStyle =
      'display:flex; flex-direction: row !important; align-items: center !important; justify-content: space-between !important;'
    const pointStyle = `border-radius:10px;width:10px;height:10px;background-color: ${item?.color};`
    const nameStyle = `margin-left: 10px; color: ${colors.primary}`
    const valueStyle = `margin-left: 10px; color: ${item?.color}; font-weight: ${FONT_WEIGHT.semibold}`
    const point = `<span style="${pointStyle}"></span>`
    const name = `<span style="${nameStyle}">${item?.seriesName ?? '-'}</span>`
    const value = `<span style="${valueStyle}">${formatMoney(
      item?.value ?? 0,
      '',
    )}</span>`
    return `<div style="${containerStyle}">
      <div style="${containerStyle}">
      ${point}
      ${name}
      </div>
      ${value}
    </div>`
  }

  const formatToolTip = (items: ISeriesData[]) => {
    const root = 'width: fit-content'
    return `<div style="${root}">
      ${itemToolTip(items?.[0])}
      ${itemToolTip(items?.[1])}
    </div>`
  }

  const getOption: EChartsOption = useMemo(() => {
    return {
      baseOption: {
        title: {
          textAlign: 'left',
          left: '5%',
        },
        tooltip: {
          formatter: formatToolTip,
        },
        legend: {
          data: data.series.map(item => item.name),
          itemGap: 35,
          itemHeight: 9,
          itemWidth: 9,
          right: '0%',
          itemStyle: {
            borderRadius: 20,
          },
          top: 8,
          icon: 'circle',
        },
        calculable: true,
        grid: {
          top: 60,
          bottom: 30,
          left: 30,
          right: 8,
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'none',
              snap: true,
              label: {
                show: true,
                formatter: params => {
                  const xLabel = params?.value
                  return `${xLabel}`
                },
                color: colors.secondaryText,
                backgroundColor: colors.white,
                fontSize: FONT_SIZE.xxxs,
                fontWeight: FONT_WEIGHT.semibold,
              },
            },
          },
        },
        xAxis: [
          {
            axisLabel: {
              interval: 0,
              rotate: 0,
              textStyle: {
                baseline: 'top',
                color: '#6D7580',
                fontSize: FONT_SIZE.xxxs,
                fontWeight: FONT_WEIGHT.semibold,
              },
            },
            data: data.categories,
            type: 'category',
            axisLine: { show: false },
            axisTick: { show: false },
          },
        ],
        yAxis: [
          {
            axisLabel: {
              textStyle: { fontSize: 10, fontWeight: FONT_WEIGHT.regular },
              formatter: (value: number) => {
                return formatMoney(value, '')
              },
            },
            axisLine: { show: false },
            axisTick: { show: false },
            name: 'Số lượng',
            nameTextStyle: {
              color: '#475467',
              fontSize: FONT_SIZE.xxxs,
              fontWeight: FONT_WEIGHT.medium,
            },
            type: 'value',
            max: Math.round((maxValue * 2) / 10 ) * 10,
          },
        ],
        series: data.series.map(item => ({
          name: item.name,
          type: 'bar',
          data: item.data,
          barWidth: 28,
          color: item?.color,
          animationDelay: (idx: number) => idx * 10,
          barGap: '10%',
          barCategoryGap: '20%',
          // border radius
          itemStyle: {
            borderRadius: 4,
          },
        })),
        dataZoom: [
          {
            type: 'inside',
            start: 0,
            end: 100,
            show: false,
            zoomLock: true,
          },
        ],
      },
    }
  }, [data, FONT_SIZE, FONT_WEIGHT, colors, maxValue])

  const clinicsOptions = useMemo(() => {
    if (unit?._id) {
      return clinics?.filter(item => item?.parentid === unit?._id)
    }
    return clinics
  }, [clinics, unit])

  return (
    <RootWrapper style={{ padding: 8 }}>
      <BaseForm>
        <BaseRow
          justify="space-between"
          align={'middle'}
          gutter={6}
        >
          <BaseCol xl={8}>
            <BaseText
              children={unit?.name ? `KV ${unit?.name}` : 'Toàn hệ thống'}
              fontWeight="semibold"
            />
          </BaseCol>

          <BaseCol xl={12}>
            <BaseForm.Item hiddenLabel style={{ marginBottom: 0 }}>
              <BaseSelect
                options={clinicsOptions?.map(item => ({
                  label: item?.name,
                  value: item?._id,
                }))}
                onChange={value => {
                  setSelectedClinics(value as string[])
                }}
                mode="multiple"
                allowClear
                showSearch
                placeholder="Tất cả"
                loading={isLoadingClinics}
                value={selectedClinics}
                maxTagCount={1}
              />
            </BaseForm.Item>
          </BaseCol>
        </BaseRow>
      </BaseForm>
      <div style={{ minHeight: 400, position: 'relative' }}>
        <BaseStackedChartWrapper option={getOption} />
        {isLoadingData && <Loading size="small" isAbsolute />}
      </div>
    </RootWrapper>
  )
}

const BaseStackedChartWrapper = styled(BaseChart)`
  width: 100%;
`

const RootWrapper = styled.div`
  padding: 8px;
  border: 1px solid #e4e7ec;
  border-radius: 8px;
  background-color: #fcfcfd;
`
