import { memo } from 'react'
import { isEqual } from 'lodash'
import { PageTitle } from 'app/components/common/PageTitle'
import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'
import { ReportGeneralFeedbackCustomerContainer } from 'app/containers/Operation/ReportGeneralFeedbackCustomer'
import { ModulePermissionEnum } from 'app/common/permission-module'

const ReportGeneralFeedbackCustomerPageCpn = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>{t(R.strings.report_general_feedback_customer)}</PageTitle>
      <ReportGeneralFeedbackCustomerContainer
        module={ModulePermissionEnum.OPERATION}
      />
    </>
  )
}
const ReportGeneralFeedbackCustomerPage = memo(
  ReportGeneralFeedbackCustomerPageCpn,
  isEqual,
)

export default ReportGeneralFeedbackCustomerPage
