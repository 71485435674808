import R from 'app/assets/R'
import { SvgMarketingFocusIcon } from 'app/assets/svg-assets'
import { FunctionPermissionEnum } from 'app/common/permission-module'
import { IGroupReport } from 'app/components/common/BaseLayoutReport/type'
import {
  OPERATION_GENERAL_FEEDBACK_CUSTOMER_PATH,
  OPERATION_REPORT_GENERAL_FEEDBACK_CUSTOMER_PATH,
} from 'app/components/router/route-path'
import { DateUtil } from 'parkway-web-common'

export const data: IGroupReport[] = [
  {
    id: 1,
    titleI18nKey: R.strings.report,
    icon: <SvgMarketingFocusIcon />,
    pages: [
      {
        id: 11,
        labelI18nKey: R.strings.general_feedback_customer,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: OPERATION_GENERAL_FEEDBACK_CUSTOMER_PATH,
        permissions: [
          FunctionPermissionEnum.OPERATION_READ_GENERAL_FEEDBACK_CUSTOMER_FOR_OPERATION,
          FunctionPermissionEnum.OPERATION_READ_GENERAL_FEEDBACK_CUSTOMER,
        ],
        isHideLabel: true,
      },
      {
        id: 12,
        labelI18nKey: R.strings.report_general_feedback_customer,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: OPERATION_REPORT_GENERAL_FEEDBACK_CUSTOMER_PATH,
        permission: FunctionPermissionEnum.OPERATION_READ_GENERAL_FEEDBACK_CUSTOMER_REPORT,
        isHideLabel: true,
      },
    ],
  },
]
