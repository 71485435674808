import { IResponseFilter } from 'app/components/tables/BaseTableReport/FilterReport/type'
import { moment, SelectFilterEnum } from 'parkway-web-common'
import { createContext, useContext, useState } from 'react'
import styled from 'styled-components'

interface IReportGeneralFeedbackCustomerContext {
  filterResponse?: IResponseFilter
  handleChangeFilter?: (filter: IResponseFilter) => void
}

const ReportGeneralFeedbackCustomerContext =
  createContext<IReportGeneralFeedbackCustomerContext>({})

export const ReportGeneralFeedbackCustomerProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [filterResponse, setFilterResponse] = useState<IResponseFilter>({
    year: `${moment().year()}`,
    month: `${moment().month() + 1}`,
    selectFilterType: SelectFilterEnum.YEAR,
  })

  const handleChangeFilter = (filter: IResponseFilter) => {
    setFilterResponse(filter)
  }

  return (
    <ReportGeneralFeedbackCustomerContext.Provider
      value={{ filterResponse, handleChangeFilter }}
    >
      <RootWrapper>{children}</RootWrapper>
    </ReportGeneralFeedbackCustomerContext.Provider>
  )
}

export const useReportGeneralFeedbackCustomerContext = () => {
  return useContext(ReportGeneralFeedbackCustomerContext)
}

export const RootWrapper = styled.div`
  padding: 8px;
`
