import { BaseSpace } from 'app/components/common/BaseSpace'
import { SelectFilterEnum } from 'parkway-web-common'
import styled from 'styled-components'
import { useReportGeneralFeedbackCustomerContext } from './context'
import { FilterLayout } from './layouts/Filter'
import { MonthLayout } from './layouts/Month'
import { YearLayout } from './layouts/Year'

export const ContentLayout = () => {
  const { filterResponse, handleChangeFilter } =
    useReportGeneralFeedbackCustomerContext()
  return (
    <LayoutWrapper>
      <FilterLayout
        isHaveChooseWeek={false}
        isSelectFilter
        handleChangeFilter={handleChangeFilter}
        rootStyle={{ paddingBottom: 0 }}
        selectFilterGroup="MONTH_YEAR"
        initSelectEnum="YEAR"
      />
      {filterResponse?.selectFilterType === SelectFilterEnum.YEAR ? (
        <YearLayout />
      ) : (
        <MonthLayout />
      )}
    </LayoutWrapper>
  )
}

const LayoutWrapper = styled(BaseSpace)`
  padding: 8px;
  background-color: #ffffff;
  border-radius: 8px;
`
