import { requestReportGeneralFeedbackCustomer } from 'app/api/operation/report-general-feedback-customer'
import { ReportGeneralFeedbackCustomerMessageCode } from 'app/api/operation/report-general-feedback-customer/contant'
import { IRecordReportGeneralFeedbackCustomer } from 'app/api/operation/report-general-feedback-customer/modal'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import BaseText from 'app/components/common/BaseText'
import { BaseChart } from 'app/components/common/charts/BaseChart'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { IUnit } from 'app/model/unit.model'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { EChartsOption } from 'echarts-for-react'
import { isEqual } from 'lodash'
import {
  FONT_SIZE,
  FONT_WEIGHT,
  ResponseType,
  colors,
  formatMoney,
  moment,
} from 'parkway-web-common'
import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { useReportGeneralFeedbackCustomerContext } from '../../../context'

export interface ISeriesData {
  color?: string
  seriesName?: string
  value?: string
}

export interface IDataChart {
  month?: string[]
  totalFeedback?: number[]
  totalComplaint?: number[]
  totalCompleted?: number[]
}

interface IProps {
  unit?: IUnit
  clinics?: IUnit[]
  isLoadingClinics?: boolean
}

export const StackedBarChart: React.FC<IProps> = ({
  unit,
  clinics,
  isLoadingClinics,
}) => {
  const [isLoadingData, setIsLoadingData] = useState(false)

  console.log({ isLoadingData })

  const [selectedClinics, setSelectedClinics] = useState<string[]>([])

  const [dataChart, setDataChart] = useState<IDataChart>({
    month: [],
    totalFeedback: [],
    totalComplaint: [],
    totalCompleted: [],
  })

  const { filterResponse } = useReportGeneralFeedbackCustomerContext()

  const maxValue = useMemo(() => {
    return Math.max(
      ...(dataChart?.totalFeedback ?? []),
      ...(dataChart?.totalComplaint ?? []),
      ...(dataChart?.totalCompleted ?? []),
    )
  }, [dataChart])

  const getData = async () => {
    try {
      setIsLoadingData(true)
      const response: ResponseType<IRecordReportGeneralFeedbackCustomer[]> =
        await requestReportGeneralFeedbackCustomer({
          year: Number(filterResponse?.year),
          areaId: unit?._id,
          locationIds: selectedClinics,
        })
      if (
        isEqual(
          response.msgcode,
          ReportGeneralFeedbackCustomerMessageCode.successGetData,
        )
      ) {
        const data = response.data

        const currentYear = moment().year()
        const endMonth =
          Number(filterResponse?.year) === currentYear
            ? moment().month() + 1
            : 12

        const filteredData = data?.filter(item => {
          const month = moment(`01/${item.time}`, 'DD/MM/YYYY').month() + 1
          return month <= endMonth
        })

        const month = filteredData?.map(item => item.time ?? '') ?? []
        const totalFeedback =
          filteredData?.map(item => item?.feedback ?? 0) ?? []
        const totalComplaint =
          filteredData?.map(item => item?.complaint ?? 0) ?? []
        const totalCompleted =
          filteredData?.map(item => item?.resolved ?? 0) ?? []

        setDataChart({
          month,
          totalFeedback,
          totalComplaint,
          totalCompleted,
        })
      }
    } catch (error) {
      console.log({ error }, '----------------')
    } finally {
      setIsLoadingData(false)
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      getData()

      return () => {
        setDataChart({})
      }
    }, 500)

    return () => clearTimeout(timer)
  }, [filterResponse?.year, selectedClinics, unit?._id])

  const data = useMemo(() => {
    return {
      categories: dataChart.month,
      series: [
        {
          name: 'Feedback',
          data: dataChart.totalFeedback,
          color: '#7CD4FD',
        },
        {
          name: 'Complaint',
          data: dataChart.totalComplaint,
          color: '#A6F4C5',
        },
        {
          name: 'Đã giải quyết',
          data: dataChart.totalCompleted,
          color: '#FDDCAB',
        },
      ],
    }
  }, [dataChart])

  const itemToolTip = (item: ISeriesData) => {
    const containerStyle =
      'display:flex; flex-direction: row !important; align-items: center !important; justify-content: space-between !important;'
    const pointStyle = `border-radius:10px;width:10px;height:10px;background-color: ${item?.color};`
    const nameStyle = `margin-left: 10px; color: ${colors.primary}`
    const valueStyle = `margin-left: 10px; color: ${item?.color}; font-weight: ${FONT_WEIGHT.semibold}`
    const point = `<span style="${pointStyle}"></span>`
    const name = `<span style="${nameStyle}">${item?.seriesName ?? '-'}</span>`
    const value = `<span style="${valueStyle}">${formatMoney(
      item?.value ?? 0,
      '',
    )}</span>`
    return `<div style="${containerStyle}">
      <div style="${containerStyle}">
      ${point}
      ${name}
      </div>
      ${value}
    </div>`
  }

  const formatToolTip = (items: ISeriesData[]) => {
    const root = 'width: fit-content'
    return `<div style="${root}">
      ${itemToolTip(items?.[0])}
      ${itemToolTip(items?.[1])}
      ${itemToolTip(items?.[2])}
    </div>`
  }

  const getOption: EChartsOption = useMemo(() => {
    return {
      baseOption: {
        title: {
          textAlign: 'left',
          left: '5%',
        },
        tooltip: {
          formatter: formatToolTip,
        },
        legend: {
          data: data.series.map(item => item.name),
          itemGap: 35,
          itemHeight: 9,
          itemWidth: 9,
          right: '0%',
          itemStyle: {
            borderRadius: 20,
          },
          top: 8,
          icon: 'circle',
        },
        calculable: true,
        grid: {
          top: 60,
          bottom: 30,
          left: 30,
          right: 8,
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'none',
              snap: true,
              label: {
                show: true,
                formatter: params => {
                  const xLabel = params?.value
                  return `${xLabel}`
                },
                color: colors.secondaryText,
                backgroundColor: colors.white,
                fontSize: FONT_SIZE.xxxs,
                fontWeight: FONT_WEIGHT.semibold,
              },
            },
          },
        },
        xAxis: [
          {
            axisLabel: {
              interval: 0,
              rotate: 0,
              textStyle: {
                baseline: 'top',
                color: '#6D7580',
                fontSize: FONT_SIZE.xxxs,
                fontWeight: FONT_WEIGHT.semibold,
              },
            },
            data: data.categories,
            type: 'category',
            axisLine: { show: false },
            axisTick: { show: false },
          },
        ],
        yAxis: [
          {
            axisLabel: {
              textStyle: { fontSize: 10, fontWeight: FONT_WEIGHT.regular },
              formatter: (value: number) => {
                return formatMoney(value, '')
              },
            },
            axisLine: { show: false },
            axisTick: { show: false },
            name: 'Số lượng',
            nameTextStyle: {
              color: '#475467',
              fontSize: FONT_SIZE.xxxs,
              fontWeight: FONT_WEIGHT.medium,
            },
            type: 'value',
            max: Math.round((maxValue * 2) / 10) * 10,
          },
        ],
        series: data.series.map(item => ({
          name: item.name,
          type: 'bar',
          data: item.data,
          barWidth: 28,
          color: item?.color,
          animationDelay: (idx: number) => idx * 10,
          barGap: '10%',
          barCategoryGap: '20%',
          // border radius
          itemStyle: {
            borderRadius: 8,
          },
        })),
        dataZoom: [
          {
            type: 'inside',
            start: 0,
            end: (() => {
              switch (dataChart?.month?.length) {
                case 1:
                case 2:
                case 3:
                  return 100
                case 4:
                case 5:
                  return 80
                default:
                  return 40
              }
            })(),
            show: false,
            zoomLock: true,
          },
        ],
      },
    }
  }, [data, FONT_SIZE, FONT_WEIGHT, colors, dataChart, maxValue])

  const clinicsOptions = useMemo(() => {
    if (unit?._id) {
      return clinics?.filter(item => item?.parentid === unit?._id)
    }
    return clinics
  }, [clinics, unit])

  return (
    <BaseForm>
      <RootWrapper style={{ padding: 8 }}>
        <BaseRow justify="space-between" align={'middle'} gutter={6}>
          <BaseCol xl={8}>
            <BaseText
              children={unit?.name ? `KV ${unit?.name}` : 'Toàn hệ thống'}
              fontWeight="semibold"
            />
          </BaseCol>

          <BaseCol xl={12}>
            <BaseForm.Item hiddenLabel style={{ marginBottom: 0 }}>
              <BaseSelectStyled
                options={clinicsOptions?.map(item => ({
                  label: item?.name,
                  value: item?._id,
                }))}
                onChange={value => {
                  setSelectedClinics(value as string[])
                }}
                mode="multiple"
                allowClear
                showSearch
                placeholder="Tất cả"
                loading={isLoadingClinics}
                value={selectedClinics}
                maxTagCount={1}
                $prefix="Phòng khám"
                $prefixWidth={80}
              />
            </BaseForm.Item>
          </BaseCol>
        </BaseRow>
        <div style={{ minHeight: 400, position: 'relative' }}>
          <BaseStackedChartWrapper option={getOption} />
        </div>
      </RootWrapper>
    </BaseForm>
  )
}

const BaseStackedChartWrapper = styled(BaseChart)`
  width: 100%;
`

const RootWrapper = styled.div`
  padding: 8px;
  padding-bottom: 2px;
  border: 1px solid #e4e7ec;
  border-radius: 8px;
  background-color: #fcfcfd;
`

const BaseSelectStyled = styled(BaseSelect)`
  .ant-select-selector::before {
    font-size: ${FONT_SIZE.xs};
    font-weight: ${FONT_WEIGHT.medium};
    color: ${convertedVariables.primaryColor};
    opacity: 0.4;
    align-items: center;
    display: flex;
    content: '${props => props?.$prefix ?? '-'}:';
    width: ${props => props?.$prefixWidth ?? 40}px;
  }

  .ant-select-selection-placeholder {
    padding-left: ${props =>
      props.$prefixWidth ? `${props?.$prefixWidth}px` : 0};
  }
`
