import { SearchOutlined } from '@ant-design/icons'
import { DefineMethodIssueEnum } from 'app/api/marketing/discount-voucher/constant'
import {
  DiscountTypeEnum,
  MethodIssueEnum,
  VoucherTemplateStatusEnum,
  VoucherTemplateStatusIssueEnum,
} from 'app/api/marketing/discount-voucher/enum'
import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { BaseDatePicker } from 'app/components/common/pickers/BaseDatePicker'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { notificationController } from 'app/controllers/notification-controller'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import dayjs, { Dayjs } from 'dayjs'
import { t } from 'i18next'
import { Dates, FONT_SIZE, FONT_WEIGHT } from 'parkway-web-common'
import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { IFilterDataTableReport } from '../../type'

interface IProps {
  onChange?: (value: IFilterDataTableReport) => void
  formData?: IFilterDataTableReport
  actionComponent?: ReactNode
}

export const FilterLayout: React.FC<IProps> = ({
  formData,
  onChange,
  actionComponent,
}) => {
  const onChangeKeyword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    onChange && onChange({ ...formData, keyword: value })
  }

  const onChangeMethodIssue = (value: MethodIssueEnum) => {
    onChange && onChange({ ...formData, methodIssue: value })
  }

  const onChangeType = (value: DiscountTypeEnum) => {
    onChange && onChange({ ...formData, type: value })
  }

  const onChangeStatus = (value: VoucherTemplateStatusEnum) => {
    onChange && onChange({ ...formData, status: value })
  }

  const onChangeCreateType = (value: VoucherTemplateStatusIssueEnum) => {
    onChange && onChange({ ...formData, createType: value })
  }

  const onChangeStartDate = (day?: Dayjs) => {
    if (day && formData?.endDate && day.isAfter(formData.endDate)) {
      notificationController?.error({
        message: t(R.strings.error),
        description: t(R.strings.start_date_must_be_before_end_date),
      })
      return
    }

    const value = day ? day?.toISOString() : undefined
    onChange && onChange({ ...formData, startDate: value })
  }

  const onChangeEndDate = (day?: Dayjs) => {
    if (day && formData?.startDate && day.isBefore(formData.startDate)) {
      notificationController?.error({
        message: t(R.strings.error),
        description: t(R.strings.end_date_must_be_after_start_date),
      })
      return
    }

    const value = day ? day?.toISOString() : undefined
    onChange && onChange({ ...formData, endDate: value })
  }

  return (
    <BaseForm>
      <RootWrapper gutter={[16, 0]} align={'middle'}>
        <BaseCol xl={6}>
          <BaseFormItem hiddenLabel>
            <BaseInput
              prefix={<SearchOutlined rev={undefined} />}
              value={formData?.keyword}
              placeholder={t(R.strings.enter_name_voucher)}
              onChange={onChangeKeyword}
              allowClear
            />
          </BaseFormItem>
        </BaseCol>
        <BaseCol xl={6}>
          <BaseFormItem hiddenLabel>
            <BaseSelectWrapper
              allowClear
              $prefix={t(R.strings.method_issue)}
              value={formData?.methodIssue}
              $prefixWidth={73}
              placeholder={t(R.strings.all)}
              onChange={value => onChangeMethodIssue(value as MethodIssueEnum)}
              options={[
                {
                  label: t(DefineMethodIssueEnum[MethodIssueEnum.Auto].keyI18n),
                  value: MethodIssueEnum.Auto,
                },
                {
                  label: t(
                    DefineMethodIssueEnum[MethodIssueEnum.Fillable].keyI18n,
                  ),
                  value: MethodIssueEnum.Fillable,
                },
              ]}
            />
          </BaseFormItem>
        </BaseCol>
        <BaseCol xl={6}>
          <BaseFormItem hiddenLabel>
            <BaseSelectWrapper
              allowClear
              $prefix={t(R.strings.discount_type)}
              value={formData?.type}
              $prefixWidth={100}
              placeholder={t(R.strings.all)}
              onChange={value => onChangeType(value as DiscountTypeEnum)}
              options={[
                {
                  label: t(R.strings.cash_discount),
                  value: DiscountTypeEnum.Amount,
                },
                {
                  label: t(R.strings.discount_percent),
                  value: DiscountTypeEnum.Percent,
                },
              ]}
            />
          </BaseFormItem>
        </BaseCol>

        <BaseCol xl={6}>{actionComponent}</BaseCol>

        <BaseCol xl={6}>
          <BaseFormItem hiddenLabel>
            <BaseSelectWrapper
              allowClear
              $prefix={t(R.strings.status)}
              value={formData?.status}
              $prefixWidth={80}
              placeholder={t(R.strings.all)}
              onChange={value =>
                onChangeStatus(value as VoucherTemplateStatusEnum)
              }
              options={[
                {
                  label: t(R.strings.waiting_apply),
                  value: VoucherTemplateStatusEnum.WaitingApply,
                },
                {
                  label: t(R.strings.applying),
                  value: VoucherTemplateStatusEnum.Applying,
                },
                {
                  label: t(R.strings.stop_apply),
                  value: VoucherTemplateStatusEnum.StopApply,
                },
                {
                  label: t(R.strings.ended),
                  value: VoucherTemplateStatusEnum.Ended,
                },
              ]}
            />
          </BaseFormItem>
        </BaseCol>
        <BaseCol xl={6}>
          <BaseFormItem hiddenLabel>
            <BaseSelectWrapper
              allowClear
              $prefix={t(R.strings.status_create)}
              value={formData?.createType}
              $prefixWidth={110}
              placeholder={t(R.strings.all)}
              onChange={value =>
                onChangeCreateType(value as VoucherTemplateStatusIssueEnum)
              }
              options={[
                {
                  label: t(R.strings.waiting),
                  value: VoucherTemplateStatusIssueEnum.Waiting,
                },
                {
                  label: t(R.strings.creating),
                  value: VoucherTemplateStatusIssueEnum.Creating,
                },
                {
                  label: t(R.strings.error),
                  value: VoucherTemplateStatusIssueEnum.Error,
                },
                {
                  label: t(R.strings.done),
                  value: VoucherTemplateStatusIssueEnum.Done,
                },
              ]}
            />
          </BaseFormItem>
        </BaseCol>

        <BaseCol xl={6}>
          <BaseFormItem hiddenLabel>
            <Picker
              $prefix={t(R.strings.start_date)}
              picker="date"
              value={
                formData?.startDate ? Dates.getDate(formData?.startDate) : null
              }
              onChange={day => onChangeStartDate(day as Dayjs)}
              allowClear
              format={'DD-MM-YYYY HH:mm'}
              placeholder={t(R.strings.all)}
              showTime={{ defaultValue: dayjs('00:00', 'HH:mm') }}
            />
          </BaseFormItem>
        </BaseCol>
        <BaseCol xl={6}>
          <BaseFormItem hiddenLabel>
            <Picker
              $prefix={t(R.strings.end_date)}
              value={
                formData?.endDate ? Dates.getDate(formData?.endDate) : null
              }
              picker="date"
              onChange={day => onChangeEndDate(day as Dayjs)}
              allowClear
              format={'DD-MM-YYYY HH:mm'}
              placeholder={t(R.strings.all)}
              showTime={{ defaultValue: dayjs('23:59', 'HH:mm') }}
            />
          </BaseFormItem>
        </BaseCol>
      </RootWrapper>
    </BaseForm>
  )
}

const RootWrapper = styled(BaseRow)`
  padding: 8px;
`

const BaseSelectWrapper = styled(BaseSelect)<{
  $prefix: string
  $prefixWidth?: number
}>`
  .width: 100%;
  .ant-select-selector::before {
    content: '${props => props?.$prefix ?? '-'}:';
    font-size: ${FONT_SIZE.xs};
    font-weight: ${FONT_WEIGHT.medium};
    color: ${convertedVariables.primaryColor};
    opacity: 0.4;
    width: ${props => props?.$prefixWidth ?? 80}px;
    align-items: center;
    display: flex;
  }
`

const BaseFormItem = styled(BaseForm.Item)`
  margin-bottom: 0px !important;
`

const Picker = styled(BaseDatePicker)<{
  $prefix: string
  $prefixWidth?: number
}>`
  width: 100%;
  border: 1px solid ${convertedVariables.neutralBlack9Color};
  &:hover {
    border: 1px solid ${convertedVariables.neutralBlack9Color};
  }
  .ant-picker-input::before {
    content: '${props => props?.$prefix ?? '-'}:';
    font-size: ${FONT_SIZE.xs};
    font-weight: ${FONT_WEIGHT.medium};
    color: ${convertedVariables.primaryColor};
    opacity: 0.4;
    min-width: ${props => props?.$prefixWidth ?? 105}px;
  }
`
