import {
  EFeedbackChannel,
  EFeedbackContentType,
  EFeedbackDepartment,
  EFeedbackLevelType,
  EFeedbackStatus,
} from './modal'

export const EFeedbackChannelMap = {
  [EFeedbackChannel.HOTLINE]: {
    label: 'Hotline',
    value: EFeedbackChannel.HOTLINE,
  },
  [EFeedbackChannel.FACEBOOK]: {
    label: 'Facebook',
    value: EFeedbackChannel.FACEBOOK,
  },
  [EFeedbackChannel.ZALO]: {
    label: 'Zalo',
    value: EFeedbackChannel.ZALO,
  },
  [EFeedbackChannel.GMAIL]: {
    label: 'Gmail',
    value: EFeedbackChannel.GMAIL,
  },
  [EFeedbackChannel.ZNS]: {
    label: 'ZNS',
    value: EFeedbackChannel.ZNS,
  },
  [EFeedbackChannel.GOOGLE_BUSINESS]: {
    label: 'Google business',
    value: EFeedbackChannel.GOOGLE_BUSINESS,
  },
  [EFeedbackChannel.INTERNAL]: {
    label: 'Internal',
    value: EFeedbackChannel.INTERNAL,
  },
  [EFeedbackChannel.OTHER]: {
    label: 'Khác',
    value: EFeedbackChannel.OTHER,
  },
}

export const EFeedbackLevelTypeMap = {
  [EFeedbackLevelType.SUGGESTION]: {
    label: 'Góp ý',
    value: EFeedbackLevelType.SUGGESTION,
  },
  [EFeedbackLevelType.SPECIAL_REQUEST]: {
    label: 'Yêu cầu biệt đãi',
    value: EFeedbackLevelType.SPECIAL_REQUEST,
  },
  [EFeedbackLevelType.COMPLAINT]: {
    label: 'Khiếu nại',
    value: EFeedbackLevelType.COMPLAINT,
  },
  [EFeedbackLevelType.GRIEVANCE]: {
    label: 'Phàn nàn',
    value: EFeedbackLevelType.GRIEVANCE,
  },
}

export const EFeedbackContentTypeMap = {
  [EFeedbackContentType.EXPERTISE]: {
    label: 'Chuyên môn',
    value: EFeedbackContentType.EXPERTISE,
  },
  [EFeedbackContentType.SERVICE]: {
    label: 'Dịch vụ',
    value: EFeedbackContentType.SERVICE,
  },
  [EFeedbackContentType.FACILITY]: {
    label: 'Cơ sở vật chất',
    value: EFeedbackContentType.FACILITY,
  },
}

export const EFeedbackDepartmentMap = {
  [EFeedbackDepartment.NURSE]: {
    label: 'Y Tá',
    value: EFeedbackDepartment.NURSE,
  },
  [EFeedbackDepartment.DOCTOR]: {
    label: 'Bác Sĩ',
    value: EFeedbackDepartment.DOCTOR,
  },
  [EFeedbackDepartment.CONSULTANT]: {
    label: 'Tư Vấn',
    value: EFeedbackDepartment.CONSULTANT,
  },
  [EFeedbackDepartment.SECURITY]: {
    label: 'Bảo Vệ',
    value: EFeedbackDepartment.SECURITY,
  },
  [EFeedbackDepartment.MARKETING]: {
    label: 'Marketing',
    value: EFeedbackDepartment.MARKETING,
  },
  [EFeedbackDepartment.PROJECT]: {
    label: 'Dự Án',
    value: EFeedbackDepartment.PROJECT,
  },
  [EFeedbackDepartment.PROCUREMENT]: {
    label: 'Mua Hàng',
    value: EFeedbackDepartment.PROCUREMENT,
  },
  [EFeedbackDepartment.HR]: {
    label: 'Nhân Sự',
    value: EFeedbackDepartment.HR,
  },
  [EFeedbackDepartment.OPERATIONS]: {
    label: 'Vận Hành',
    value: EFeedbackDepartment.OPERATIONS,
  },
  [EFeedbackDepartment.LEGAL]: {
    label: 'LEGAL',
    value: EFeedbackDepartment.LEGAL,
  },
  [EFeedbackDepartment.OTHER]: {
    label: 'Khác',
    value: EFeedbackDepartment.OTHER,
  },
}

export const EFeedbackStatusMap = {
  [EFeedbackStatus.WAIT_PROGRESS]: {
    label: 'Chưa xử lý',
    value: EFeedbackStatus.WAIT_PROGRESS,
  },
  [EFeedbackStatus.IN_PROGRESS]: {
    label: 'Đang xử lý',
    value: EFeedbackStatus.IN_PROGRESS,
  },
  [EFeedbackStatus.PROCESSED]: {
    label: 'Đã xử lý',
    value: EFeedbackStatus.PROCESSED,
  },
}

// Endpoint
export const GeneralFeedbackCustomerPoint = {
  Get: 'crm/v1/customer-feedback',
  Create: 'crm/v1/customer-feedback/create',
  Update: 'crm/v1/customer-feedback/update/:id',
  Delete: 'crm/v1/customer-feedback/delete',
  Resolve: 'crm/v1/customer-feedback/resolve/:id',
  //export excel
  ExportExcel: 'crm/v1/customer-feedback/export/excel',
}

export const GeneralFeedbackCustomerMessageCode = {
  successGetData: '20000',
  successExportData: '20000',
}