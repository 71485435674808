/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  DefineCampaignReferralEnum,
  DefineDiscountCampaignType,
} from 'app/api/marketing/discount-campaign/constant'
import {
  ApplyTypeEnum,
  CampaignReferralEnum,
  CampaignTypeEnum,
  ICampaignGroup,
  DiscountOnPriceEnum,
} from 'app/api/marketing/discount-campaign/model'
import R from 'app/assets/R'
import { YesNoEnum } from 'app/common/enum'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseEmpty } from 'app/components/common/BaseEmpty'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import BaseTextEditor from 'app/components/common/BaseTextEditor'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { BaseDatePicker } from 'app/components/common/pickers/BaseDatePicker'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { BaseTreeSelect } from 'app/components/common/selects/BaseTreeSelect'
import { transformDataSelectTree } from 'app/components/common/selects/BaseTreeSelect/helper'
import { usePagination } from 'app/hook'
import { useGetCampaignGroupAll } from 'app/react-query/hook/campaign'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { isEqual } from 'lodash'
import { BORDER_RADIUS, PADDING, ResponseType } from 'parkway-web-common'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useDetailCampaignContext } from '../../context'
import { ListCampaignGroupLayout } from './layouts'

export const InfoCampaignLayout = () => {
  const { t } = useTranslation()
  const {
    areaList,
    isLoadingArea,
    filterTreeNode,
    keywordSearchArea,
    setKeywordSearchArea,
    selectedClinic,
    campaignType,
    isApplyWithOtherCampaign,
    campaignApplyCommon,
    info,
    isApplyPaymentDiscount
  } = useDetailCampaignContext()

  const { flattenDataList } = usePagination()

  const { data: campaignGroupAllData, isLoading: loadingCampaignGroupAll } =
    useGetCampaignGroupAll()

  const campaignGroupAll = useMemo(() => {
    const res: ResponseType<ICampaignGroup[]> =
      flattenDataList(campaignGroupAllData)

    return res?.data
  }, [campaignGroupAllData])

  return (
    <RootWrapper>
      <HeaderWrapper>
        <BaseText
          children={t(R.strings.campaign_info)}
          fontWeight="semibold"
          fontSize="md"
        />
      </HeaderWrapper>
      <ContentWrapper size={4}>
        <BaseRow>
          <BaseCol xl={24}>
            <BaseForm.Item name={'name'} label={t(R.strings.campaign_name)}>
              <BaseInput
                placeholder={t(R.strings.enter_campaign_name)}
                disabled
              />
            </BaseForm.Item>
          </BaseCol>
        </BaseRow>
        <BaseRow>
          <BaseCol xl={24}>
            <BaseForm.Item
              name="group_campaign"
              label={t(R.strings.group_campaign)}
              required
            >
              <BaseSelect
                placeholder={t(R.strings.select_group_campaign)}
                options={campaignGroupAll?.map(item => ({
                  label: item?.name,
                  value: item?.code,
                }))}
                loading={loadingCampaignGroupAll}
                disabled
              />
            </BaseForm.Item>
          </BaseCol>
        </BaseRow>
        <BaseRow gutter={[16, 8]} align={'top'}>
          <BaseCol xl={12}>
            <BaseForm.Item name={'clinics'} label={t(R.strings.apply_clinic)}>
              <BaseTreeSelect
                placeholder={t(R.strings.select_clinic_apply)}
                loading={isLoadingArea}
                treeData={transformDataSelectTree(areaList ?? [], {
                  title: 'name',
                  value: '_id',
                  children: 'childs',
                })}
                value={selectedClinic}
                multiple
                showSearch
                treeCheckable={true}
                onSearch={keyword => setKeywordSearchArea?.(keyword)}
                filterTreeNode={filterTreeNode}
                searchValue={keywordSearchArea}
                onChange={() => undefined}
                disabled
              />
            </BaseForm.Item>
          </BaseCol>
          <BaseCol xl={12}>
            <BaseRow gutter={[16, 8]} align={'top'}>
              <BaseCol xl={12}>
                <BaseForm.Item
                  name={'startDate'}
                  label={t(R.strings.start_date)}
                >
                  <BaseDatePickerStyled
                    format={'DD-MM-YYYY HH:mm'}
                    placeholder={t(R.strings.start_date)}
                    disabled
                  />
                </BaseForm.Item>
              </BaseCol>
              <BaseCol xl={12}>
                <BaseForm.Item name={'endDate'} label={t(R.strings.end_date)}>
                  <BaseDatePickerStyled
                    format={'DD-MM-YYYY HH:mm'}
                    placeholder={t(R.strings.end_date)}
                    disabled
                  />
                </BaseForm.Item>
              </BaseCol>
            </BaseRow>
          </BaseCol>
        </BaseRow>
        <BaseRow gutter={[16, 8]} align={'top'}>
          <BaseCol xl={6}>
            <BaseForm.Item label={t(R.strings.active_type)} name={'activeType'}>
              <BaseSelect
                placeholder={t(R.strings.select_active_type)}
                disabled
                options={[
                  {
                    label: t(R.strings.voucher),
                    value: ApplyTypeEnum.Voucher,
                  },
                  {
                    label: t(R.strings.auto),
                    value: ApplyTypeEnum.Auto,
                  },
                ]}
              />
            </BaseForm.Item>
          </BaseCol>
          <BaseCol xl={6}>
            <BaseForm.Item
              label={t(R.strings.apply_with_other_campaign)}
              name={'applyWithOtherCampaign'}
            >
              <BaseSelect
                placeholder={t(R.strings.choose_apply_with_other_campaign)}
                options={[
                  {
                    label: t(R.strings.yes),
                    value: YesNoEnum.Yes,
                  },
                  {
                    label: t(R.strings.no),
                    value: YesNoEnum.No,
                  },
                ]}
                disabled
              />
            </BaseForm.Item>
          </BaseCol>
          <BaseCol xl={12}>
            <BaseForm.Item
              label={t(R.strings.type_of_offer)}
              name={'campaignType'}
            >
              <BaseSelect
                placeholder={t(R.strings.select_type_of_offer)}
                disabled
                value={campaignType}
                defaultValue={campaignType}
                options={[
                  {
                    label: t(
                      DefineDiscountCampaignType[CampaignTypeEnum.Treatments]
                        .keyI18n,
                    ),
                    value: CampaignTypeEnum.Treatments,
                  },
                  {
                    label: t(
                      DefineDiscountCampaignType[CampaignTypeEnum.BuyOneGetOne]
                        .keyI18n,
                    ),
                    value: CampaignTypeEnum.BuyOneGetOne,
                  },
                  {
                    label: t(
                      DefineDiscountCampaignType[CampaignTypeEnum.Voucher]
                        .keyI18n,
                    ),
                    value: CampaignTypeEnum.Voucher,
                  },
                  {
                    label: t(
                      DefineDiscountCampaignType[CampaignTypeEnum.Bundle]
                        .keyI18n,
                    ),
                    value: CampaignTypeEnum.Bundle,
                  },
                  {
                    label: t(
                      DefineDiscountCampaignType[CampaignTypeEnum.TotalBill]
                        .keyI18n,
                    ),
                    value: CampaignTypeEnum.TotalBill,
                  },
                ]}
              />
            </BaseForm.Item>
          </BaseCol>
        </BaseRow>

        <BaseRow gutter={[16, 8]} align={'top'}>
          <BaseCol xl={6}>
            <BaseForm.Item label={t(R.strings.priority)} name={'priority'}>
              <BaseInput disabled />
            </BaseForm.Item>
          </BaseCol>
          <BaseCol xl={18}>
            {/* <BaseForm.Item
              name="discountOnPrice"
              label={t(R.strings.discount_form)}
            >
              <BaseSelect
                placeholder={t(R.strings.select)}
                options={[
                  {
                    label: t(
                      DefineDiscountOnPriceEnum[DiscountOnPriceEnum.PerPrice]
                        ?.keyI18n,
                    ),
                    value: DiscountOnPriceEnum.PerPrice,
                  },
                  {
                    label: t(
                      DefineDiscountOnPriceEnum[DiscountOnPriceEnum.AfterDiscount]
                        ?.keyI18n,
                    ),
                    value: DiscountOnPriceEnum.AfterDiscount,
                  },
                ]}
                disabled
              />
            </BaseForm.Item> */}

            <BaseForm.Item name="referral" label={t(R.strings.campaign_type)}>
              <BaseSelect
                placeholder={t(R.strings.select_campaign_type)}
                options={[
                  {
                    label: t(
                      DefineCampaignReferralEnum[CampaignReferralEnum.Normal]
                        ?.keyI18n,
                    ),
                    value: CampaignReferralEnum.Normal,
                  },
                  {
                    label: t(
                      DefineCampaignReferralEnum[CampaignReferralEnum.Patient]
                        ?.keyI18n,
                    ),
                    value: CampaignReferralEnum.Patient,
                  },
                  {
                    label: t(
                      DefineCampaignReferralEnum[CampaignReferralEnum.Staff]
                        ?.keyI18n,
                    ),
                    value: CampaignReferralEnum.Staff,
                  },
                  {
                    label: t(
                      DefineCampaignReferralEnum[CampaignReferralEnum.OnlyUser]
                        ?.keyI18n,
                    ),
                    value: CampaignReferralEnum.OnlyUser,
                  },
                ]}
                disabled
              />
            </BaseForm.Item>
          </BaseCol>
        </BaseRow>

        {/* <BaseRow gutter={[16, 8]} align={'top'}>
          <BaseCol xl={6}>
            <BaseForm.Item label={t(R.strings.apply_payment_discount)}>
              <BaseSwitch disabled checked={isApplyPaymentDiscount} />
            </BaseForm.Item>
          </BaseCol>
          <BaseCol xl={18}>
            <BaseForm.Item name="referral" label={t(R.strings.campaign_type)}>
              <BaseSelect
                placeholder={t(R.strings.select_campaign_type)}
                options={[
                  {
                    label: t(
                      DefineCampaignReferralEnum[CampaignReferralEnum.Normal]
                        ?.keyI18n,
                    ),
                    value: CampaignReferralEnum.Normal,
                  },
                  {
                    label: t(
                      DefineCampaignReferralEnum[CampaignReferralEnum.Patient]
                        ?.keyI18n,
                    ),
                    value: CampaignReferralEnum.Patient,
                  },
                  {
                    label: t(
                      DefineCampaignReferralEnum[CampaignReferralEnum.Staff]
                        ?.keyI18n,
                    ),
                    value: CampaignReferralEnum.Staff,
                  },
                  {
                    label: t(
                      DefineCampaignReferralEnum[CampaignReferralEnum.OnlyUser]
                        ?.keyI18n,
                    ),
                    value: CampaignReferralEnum.OnlyUser,
                  },
                ]}
                disabled
              />
            </BaseForm.Item>
          </BaseCol>
        </BaseRow> */}

        {isEqual(isApplyWithOtherCampaign, YesNoEnum.Yes) ? (
          <BaseRow>
            <BaseCol xl={24}>
              <BaseForm.Item
                name="campaign_apply_common"
                label={t(R.strings.campaign_apply_common)}
                required
              >
                <BaseSelect
                  placeholder={t(R.strings.select_campaign_apply_common)}
                  options={[
                    {
                      label: t(R.strings.all),
                      value: R.strings.all,
                    },
                    {
                      label: t(R.strings.campaign_list),
                      value: R.strings.campaign_list,
                    },
                  ]}
                  filterSort={() => 0}
                  disabled
                />
              </BaseForm.Item>
            </BaseCol>
          </BaseRow>
        ) : null}

        <BaseSpace>
          {isEqual(campaignApplyCommon, R.strings.campaign_list) &&
          isEqual(isApplyWithOtherCampaign, YesNoEnum.Yes) &&
          !!info?.endDate &&
          !!info?.startDate &&
          !!info?.clinics?.length ? (
            <BaseSpace>
              <BaseRow
                gutter={[16, 16]}
                align={'middle'}
                justify={'space-between'}
              >
                <BaseCol>
                  <BaseText
                    children={t(R.strings.campaign_list)}
                    fontWeight="semibold"
                    fontSize="xs"
                  />
                </BaseCol>
              </BaseRow>

              <ListCampaignWrapper>
                <ListCampaignGroupLayout />
              </ListCampaignWrapper>
            </BaseSpace>
          ) : null}

          <BaseRow>
            <BaseCol xl={24}>
              <BaseForm.Item
                label={t(R.strings.description)}
                name="description"
              >
                <BaseTextEditor disabled />
              </BaseForm.Item>
            </BaseCol>
          </BaseRow>
        </BaseSpace>
      </ContentWrapper>
    </RootWrapper>
  )
}

const RootWrapper = styled.div`
  border-radius: ${BORDER_RADIUS};
  background-color: ${convertedVariables.backgroundColor};
`
const HeaderWrapper = styled(BaseRow)`
  padding: ${PADDING.md};
  border-bottom: 0.75px solid ${convertedVariables.neutralBlack9Color};
`

const ContentWrapper = styled(BaseSpace)`
  padding: ${PADDING.md};
  background-color: #fcfcfc;
  border-end-end-radius: ${BORDER_RADIUS};
`

const BaseDatePickerStyled = styled(BaseDatePicker)`
  width: 100%;
`

const ListCampaignWrapper = styled(BaseSpace)`
  border-radius: ${BORDER_RADIUS};
  background-color: ${convertedVariables.neutralBlack9Color};
  padding: ${PADDING.md};
`
