import { memo } from 'react'
import { isEqual } from 'lodash'
import { PageTitle } from 'app/components/common/PageTitle'
import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'
import OperationContainer from 'app/containers/Operation'

const OperationPageCpn = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>{t(R.strings.operation)}</PageTitle>
      <OperationContainer />
    </>
  )
}
const OperationPage = memo(OperationPageCpn, isEqual)

export default OperationPage
