import { ApiClient, ApiClientCRM } from 'app/service/api-service'
import { GeneralFeedbackCustomerPoint } from './contant'
import {
  ICreateCustomerFeedback,
  IQueryCustomerFeedback,
  IResolveCustomerFeedback,
  IUpdateCustomerFeedback,
} from './modal'

export const requestGeneralFeedbackCustomer = (
  params: IQueryCustomerFeedback,
) =>
  ApiClientCRM.Get({
    url: GeneralFeedbackCustomerPoint.Get,
    params: { params },
  })

export const requestCreateGeneralFeedbackCustomer = (
  body?: ICreateCustomerFeedback,
) =>
  ApiClientCRM.Post({
    url: GeneralFeedbackCustomerPoint.Create,
    body,
  })

export const requestUpdateGeneralFeedbackCustomer = (
  id: string,
  body?: IUpdateCustomerFeedback,
) =>
  ApiClientCRM.Put({
    url: GeneralFeedbackCustomerPoint.Update.replace(':id', id),
    body,
  })

// resolve
export const requestResolveGeneralFeedbackCustomer = (
  id: string,
  body?: IResolveCustomerFeedback,
) =>
  ApiClientCRM.Put({
    url: GeneralFeedbackCustomerPoint.Resolve.replace(':id', id),
    body,
  })

export const requestExportExcelGeneralFeedbackCustomer = (
  params: IQueryCustomerFeedback,
) =>
  ApiClient.Get({
    url: GeneralFeedbackCustomerPoint.ExportExcel,
    params: { params },
  })
