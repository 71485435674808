import {
  CloseCircleOutlined,
  PlusCircleOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import {
  EFeedbackChannelMap,
  EFeedbackContentTypeMap,
  EFeedbackDepartmentMap,
  EFeedbackLevelTypeMap,
  EFeedbackStatusMap,
} from 'app/api/operation/general-feedback-customer/contant'
import {
  EFeedbackContentType,
  EFeedbackDepartment,
  EFeedbackLevelType,
  ICustomerFeedback,
  IUpdateCustomerFeedback,
} from 'app/api/operation/general-feedback-customer/modal'
import { IPatient } from 'app/api/patient/model/patient'
import {
  SvgBuilding05Icon,
  SvgCalendarIcon,
  SvgFile05Icon,
  SvgIdIcon,
  SvgMonitor02Icon,
  SvgNotificationTextIcon,
  SvgPhoneIcon,
  SvgStar01Icon,
  SvgStarWhiteIcon,
} from 'app/assets/svg-assets'
import { getDetailPatientUrl } from 'app/common/helpers'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCheckbox } from 'app/components/common/BaseCheckbox'
import { BaseCol } from 'app/components/common/BaseCol'
import { BasePopover } from 'app/components/common/BasePopover'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { Loading } from 'app/components/common/Loading'
import { IRefModal, ModalComponent } from 'app/components/common/ModalComponent'
import { BaseDatePicker } from 'app/components/common/pickers/BaseDatePicker'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { RenderValueTable } from 'app/components/tables/BaseTableManagement/hook'
import { PatientContext } from 'app/context/PatientContext'
import { notificationController } from 'app/controllers/notification-controller'
import { formatPhoneNumber } from 'app/hook'
import { useUpdateCustomerFeedback } from 'app/react-query/hook/general-feedback-customer'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { unionBy } from 'lodash'
import { DateUtil, FORMAT_DD_MM_YYYY } from 'parkway-web-common'
import {
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { FeedbackTypeItem, geColorFeedback, StarWrapper } from '../../hook'
import dayjs from 'dayjs'

const InfoComp = ({
  icon,
  label,
  value,
}: {
  icon: ReactNode
  label: string
  value?: string | ReactNode
}) => {
  return (
    <BaseRow gutter={[8, 0]} align={'top'}>
      <BaseCol style={{ paddingTop: 4 }}>{icon}</BaseCol>
      <BaseCol>
        <BaseSpace size={4}>
          <BaseText
            children={label}
            style={{ color: '#2C3A4B' }}
            fontSize="xxxs"
          />
          <BaseText children={value} fontWeight="medium" fontSize="xxs" />
        </BaseSpace>
      </BaseCol>
    </BaseRow>
  )
}

const DetailFeedbackComp = ({
  icon,
  label,
  value,
}: {
  icon: ReactNode
  label: string
  value?: string | ReactNode
}) => {
  return (
    <BaseSpace size={8}>
      <BaseRow gutter={[8, 0]} align={'middle'}>
        <BaseCol>{icon}</BaseCol>
        <BaseCol>
          <BaseText
            children={label}
            style={{ color: '#2C3A4B' }}
            fontSize="xxxs"
          />
        </BaseCol>
      </BaseRow>
      <BaseText children={value} fontWeight="medium" fontSize="xxs" />
    </BaseSpace>
  )
}

const ButtonSelectPatient = ({
  patients,
  patientSelected,
  setPatientSelected,
  setSearchPatient,
  loading,
}: {
  patients: IPatient[]
  patientSelected?: IPatient
  setPatientSelected: (patient: IPatient) => void
  setSearchPatient: (value: string) => void
  loading: boolean
}) => {
  return (
    <BasePopover
      trigger={'click'}
      content={
        <BaseSpace>
          <BaseInput
            placeholder="Tìm kiếm khách hàng"
            onChange={value => {
              setSearchPatient(value?.target?.value ?? '')
            }}
            prefix={<SearchOutlined rev={undefined} />}
          />

          <BaseSpace
            style={{
              maxHeight: 400,
              overflow: 'auto',
              width: 300,
              position: 'relative',
            }}
            size={8}
          >
            {patients?.map((item, idx) => {
              const isSelected = patientSelected?._id === item._id
              return (
                <BaseSpace
                  style={{
                    cursor: 'pointer',
                    padding: 8,
                    borderRadius: 8,
                    backgroundColor: isSelected ? `#F9FAFB` : 'white',
                  }}
                  onClick={() => {
                    setPatientSelected(item)
                  }}
                  key={idx}
                  size={4}
                >
                  <BaseText
                    children={item.name}
                    style={{ color: '#344054' }}
                    fontWeight="semibold"
                  />
                  <BaseText
                    children={formatPhoneNumber(item.phone ?? '')}
                    style={{ color: '#667085' }}
                  />
                </BaseSpace>
              )
            })}
            {loading ? <Loading size="small" isAbsolute /> : null}
          </BaseSpace>
        </BaseSpace>
      }
    >
      <BaseButton
        icon={
          <PlusCircleOutlined
            rev={undefined}
            style={{ color: convertedVariables.collapseBackgroundColor }}
          />
        }
        children={
          <BaseText
            children={'Chọn khách hàng'}
            colorText="collapseBackgroundColor"
          />
        }
        type="text"
      />
    </BasePopover>
  )
}

export const UpdateUnidentifiedModal = ({
  record,
}: {
  record?: ICustomerFeedback
}) => {
  const { t } = useTranslation()
  const refModal = useRef<IRefModal>({})
  const [form] = BaseForm.useForm<IUpdateCustomerFeedback>()
  const [tags, setTags] = useState<EFeedbackDepartment[]>([])
  const [levelTypes, setLevelTypes] = useState<EFeedbackLevelType[]>([])
  const [contentTypes, setContentTypes] = useState<EFeedbackContentType[]>([])
  const [patientSelected, setPatientSelected] = useState<IPatient>()
  const { mutateAsync: update, isLoading } = useUpdateCustomerFeedback()

  const onHide = () => {
    refModal.current?.hide?.()
  }

  const onOpen = () => {
    refModal.current?.open?.()
  }

  const onPressSubmit = async (values?: IUpdateCustomerFeedback) => {
    if (!record?._id) {
      notificationController.error({
        message: 'Không tìm thấy feedback',
      })
      return
    }
    const payload: IUpdateCustomerFeedback = {
      departments: tags,
      resolveAt: values?.resolveAt,
      status: values?.status,
      resolveMethod: values?.resolveMethod,
      result: values?.result,
      levelTypes: levelTypes,
      contentTypes: contentTypes,
      patientId: patientSelected?._id,
    }
    const res = await update({ id: record?._id, payload })
    if (res) {
      notificationController.success({
        message: 'Cập nhật thành công',
      })
      onHide()
    }
  }

  const isDisableSubmit =
    !tags.length ||
    !levelTypes.length ||
    !contentTypes.length ||
    !patientSelected

  const [searchPatient, setSearchPatient] = useState('')

  const {
    fetchDataAppointmentPatient: fetchPatient,
    patients: patientsContext,
    patientProfiles: patientProfilesContext,
    loading: loadingPatient,
  } = useContext(PatientContext)

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchPatient?.({
        page: 1,
        pageSize: 20,
        keyword: searchPatient,
      })
    }, 500)
    return () => clearTimeout(timer)
  }, [searchPatient])

  const patients = useMemo(() => {
    return unionBy(patientsContext, patientProfilesContext, '_id')
  }, [patientsContext, patientProfilesContext])

  return (
    <ModalComponent
      ref={refModal}
      titleModal="Cập nhật"
      isLoadingConfirm={isLoading}
      buttonOpenCustom={
        <BaseButton type="text" onClick={onOpen}>
          <BaseText children={'Cập nhật'} fontWeight="medium" />
        </BaseButton>
      }
      isDisableSubmit={isDisableSubmit}
      renderContent={
        <ContentWrapper>
          <BaseCol xl={8}>
            <InfoSection>
              <BaseSpace size={8}>
                <BaseText
                  children={'Thông tin chung'}
                  fontWeight="medium"
                  style={{ color: '#365B7E' }}
                />
                <BaseRow gutter={16} align={'middle'} justify={'space-between'}>
                  <BaseCol xl={12}>
                    <InfoComp
                      icon={<SvgCalendarIcon />}
                      label={'Ngày tiếp nhận feedback'}
                      value={DateUtil.formatDate(
                        record?.createdAt ?? '',
                        FORMAT_DD_MM_YYYY,
                      )}
                    />
                  </BaseCol>
                  <BaseCol xl={12}>
                    <InfoComp
                      icon={<SvgBuilding05Icon />}
                      label={'Phòng khám'}
                      value={record?.locationName}
                    />
                  </BaseCol>
                </BaseRow>

                <BaseRow gutter={16} align={'middle'} justify={'space-between'}>
                  <BaseCol xl={24}>
                    <InfoComp
                      icon={<SvgMonitor02Icon />}
                      label={'Kênh feedback'}
                      value={
                        record?.channel
                          ? EFeedbackChannelMap?.[record?.channel]?.label
                          : '-'
                      }
                    />
                  </BaseCol>
                </BaseRow>

                <InfoComp
                  icon={<SvgFile05Icon />}
                  label={'Phân loại nội dung feedback'}
                  value={
                    <BaseRow gutter={[8, 8]} align="middle">
                      {record?.contentTypes?.map(item => {
                        return (
                          <BaseCol>
                            <FeedbackTypeItem type={item} />
                          </BaseCol>
                        )
                      })}
                    </BaseRow>
                  }
                />
              </BaseSpace>

              <LineDividerStyled />

              <BaseSpace size={8}>
                <BaseText children={'Chi tiết phản hồi'} fontWeight="medium" />

                <DetailFeedbackComp
                  icon={<SvgStar01Icon />}
                  label={'Điểm đánh giá'}
                  value={
                    record?.rate ? (
                      <StarWrapper
                        $backgroundColor={
                          convertedVariables[
                            geColorFeedback(record?.rate).background
                          ]
                        }
                        gutter={8}
                        align={'middle'}
                        justify={'center'}
                      >
                        <BaseCol>
                          <BaseText
                            children={record?.rate}
                            fontWeight="semibold"
                            textAlign="center"
                            colorText={'backgroundColor'}
                          />
                        </BaseCol>
                        <SvgStarWhiteIcon />
                      </StarWrapper>
                    ) : (
                      '-'
                    )
                  }
                />

                <DetailFeedbackComp
                  icon={<SvgNotificationTextIcon />}
                  label={'Nội dung feedback'}
                  value={
                    <FeedbackContent>
                      <BaseText children={record?.dynamicContent} />
                    </FeedbackContent>
                  }
                />
              </BaseSpace>
            </InfoSection>
          </BaseCol>
          <FormSection xl={16}>
            <BaseForm form={form} onFinish={onPressSubmit}>
              <FormContent>
                <BaseFormItem label={'Khách hàng'} required>
                  {patientSelected ? (
                    <BaseRow justify={'space-between'} gutter={16}>
                      <BaseCol>
                        <BaseSpace size={4}>
                          <RenderValueTable
                            value={patientSelected?.name ?? '-'}
                            type="OtherLink"
                            hrefOtherLink={getDetailPatientUrl(
                              patientSelected?.id ?? '',
                            )}
                          />
                          <BaseRow
                            gutter={8}
                            align={'middle'}
                            style={{ marginInline: 0 }}
                          >
                            <BaseCol>
                              <BaseRow gutter={8} align={'middle'}>
                                <SvgIdIcon />
                                <BaseCol>
                                  <BaseText
                                    children={patientSelected?.id ?? '-'}
                                  />
                                </BaseCol>
                              </BaseRow>
                            </BaseCol>
                            <BaseCol>
                              <BaseRow gutter={8} align={'middle'}>
                                <SvgPhoneIcon />
                                <BaseCol>
                                  <BaseText
                                    children={formatPhoneNumber(
                                      patientSelected?.phone ?? '',
                                    )}
                                  />
                                </BaseCol>
                              </BaseRow>
                            </BaseCol>
                          </BaseRow>
                        </BaseSpace>
                      </BaseCol>
                      <BaseCol>
                        <ButtonSelectPatient
                          patients={patients}
                          patientSelected={patientSelected}
                          setPatientSelected={setPatientSelected}
                          setSearchPatient={setSearchPatient}
                          loading={loadingPatient}
                        />
                      </BaseCol>
                    </BaseRow>
                  ) : (
                    <ButtonSelectPatient
                      patients={patients}
                      patientSelected={patientSelected}
                      setPatientSelected={setPatientSelected}
                      setSearchPatient={setSearchPatient}
                      loading={loadingPatient}
                    />
                  )}
                </BaseFormItem>

                <BaseRow>
                  <BaseCol xl={6}>
                    <BaseRow gutter={4} align={'top'}>
                      <BaseCol>
                        <BaseText
                          children={'Phân loại mức độ'}
                          fontWeight="medium"
                          opacity="0.9"
                        />
                      </BaseCol>
                      <BaseCol>
                        <BaseText children={'*'} colorText="statesRedColor" />
                      </BaseCol>
                    </BaseRow>
                  </BaseCol>
                  <BaseCol xl={18}>
                    <BaseRow gutter={4} align={'top'}>
                      {Object.values(EFeedbackLevelTypeMap).map(item => {
                        return (
                          <BaseCol xl={6}>
                            <BaseCheckbox
                              children={item.label}
                              checked={levelTypes.includes(item.value)}
                              onChange={() => {
                                setLevelTypes(
                                  levelTypes.includes(item.value)
                                    ? levelTypes.filter(
                                        type => type !== item.value,
                                      )
                                    : [...levelTypes, item.value],
                                )
                              }}
                              style={{
                                fontSize: 12,
                              }}
                            />
                          </BaseCol>
                        )
                      })}
                    </BaseRow>
                  </BaseCol>
                </BaseRow>

                <BaseRow>
                  <BaseCol xl={6}>
                    <BaseRow gutter={4} align={'top'}>
                      <BaseCol>
                        <BaseText
                          children={'Phân loại nội dung feedback'}
                          fontWeight="medium"
                          opacity="0.9"
                        />
                      </BaseCol>
                      <BaseCol>
                        <BaseText children={'*'} colorText="statesRedColor" />
                      </BaseCol>
                    </BaseRow>
                  </BaseCol>
                  <BaseCol xl={18}>
                    <BaseRow gutter={4} align={'top'}>
                      {Object.values(EFeedbackContentTypeMap).map(item => {
                        return (
                          <BaseCol xl={6}>
                            <BaseCheckbox
                              children={item.label}
                              checked={contentTypes.includes(item.value)}
                              onChange={() => {
                                setContentTypes(
                                  contentTypes.includes(item.value)
                                    ? contentTypes.filter(
                                        type => type !== item.value,
                                      )
                                    : [...contentTypes, item.value],
                                )
                              }}
                              style={{
                                fontSize: 12,
                              }}
                            />
                          </BaseCol>
                        )
                      })}
                    </BaseRow>
                  </BaseCol>
                </BaseRow>

                <BaseFormItem
                  required
                  label={'Phương án phản hồi KH'}
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng nhập phương án phản hồi KH',
                    },
                  ]}
                  name={'resolveMethod'}
                >
                  <BaseInput.TextArea placeholder="Nhập nội dung" />
                </BaseFormItem>
                <BaseFormItem label={'Kết quả'} name={'result'}>
                  <BaseInput.TextArea placeholder="Nhập nội dung" />
                </BaseFormItem>
                <BaseRow gutter={16} align={'top'} justify={'space-between'}>
                  <BaseCol xl={12}>
                    <BaseFormItem
                      required
                      label={'Ngày giải quyết'}
                      name={'resolveAt'}
                      rules={[
                        {
                          required: true,
                          message: 'Vui lòng chọn ngày giải quyết',
                        },
                      ]}
                    >
                      <BaseDatePicker
                        format={FORMAT_DD_MM_YYYY}
                        placeholder="Chọn ngày giải quyết"
                        disabledDate={current => {
                          return current < dayjs(record?.receptionAt)
                        }}
                      />
                    </BaseFormItem>
                  </BaseCol>
                  <BaseCol xl={12}>
                    <BaseFormItem
                      required
                      label={'Trạng thái'}
                      name={'status'}
                      rules={[
                        {
                          required: true,
                          message: 'Vui lòng chọn trạng thái',
                        },
                      ]}
                    >
                      <BaseSelect
                        placeholder="Chọn trạng thái"
                        options={Object.values(EFeedbackStatusMap).map(
                          item => ({
                            label: item.label,
                            value: item.value,
                          }),
                        )}
                      />
                    </BaseFormItem>
                  </BaseCol>
                </BaseRow>

                <BaseFormItem required label={'Bộ phận liên quan'}>
                  <BaseSpace>
                    <TagContainer gutter={[16, 8]}>
                      {tags.map(item => {
                        return (
                          <BaseCol>
                            <TagItem gutter={8} align={'middle'}>
                              <BaseCol>
                                <BaseText
                                  children={
                                    EFeedbackDepartmentMap?.[item]?.label
                                  }
                                  fontSize="xxxs"
                                />
                              </BaseCol>
                              <BaseCol>
                                <CloseCircleOutlined
                                  rev={undefined}
                                  style={{
                                    width: 12,
                                    height: 12,
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => {
                                    setTags(tags.filter(tag => tag !== item))
                                  }}
                                />
                              </BaseCol>
                            </TagItem>
                          </BaseCol>
                        )
                      })}
                      {tags?.length === 0 && (
                        <BaseText children={'Tag'} fontSize="xxxs" />
                      )}
                    </TagContainer>
                  </BaseSpace>
                </BaseFormItem>

                <BaseSpace size={8}>
                  <BaseText children={'Chọn tag'} fontSize="xxxs" />
                  <BaseRow gutter={[8, 8]} align={'middle'}>
                    {Object.values(EFeedbackDepartmentMap).map(item => {
                      const isSelected = tags.includes(item.value)
                      return (
                        <BaseCol
                          onClick={() => {
                            setTags(tags.concat(item.value))
                          }}
                        >
                          <TagOption $isSelected={isSelected}>
                            <BaseText
                              children={item.label}
                              style={{
                                color: isSelected ? '#667085' : '#1D2939',
                              }}
                            />
                          </TagOption>
                        </BaseCol>
                      )
                    })}
                  </BaseRow>
                </BaseSpace>
              </FormContent>
            </BaseForm>
          </FormSection>
        </ContentWrapper>
      }
      justifyButtons="end"
      handleSubmit={form.submit}
      widthModal={1200}
      isShowLineBottom={false}
      isShowLineTop={false}
      paddingContent={'0px'}
      style={{ top: 60 }}
    />
  )
}

const LineDividerStyled = styled.div`
  border-top: 1px solid ${convertedVariables.neutralBlack9Color};
`

const ContentWrapper = styled(BaseRow)`
  border-top: 1px solid #f2f4f7;
  border-bottom: 1px solid #f2f4f7;
`

const InfoSection = styled(BaseSpace)`
  padding: 16px;
`

const FormSection = styled(BaseCol)`
  border-left: 1px solid #f2f4f7;
  background-color: #fcfcfc;
`

const FormContent = styled(BaseSpace)`
  padding: 16px;
`

const FeedbackContent = styled.div`
  background-color: #f9fafb;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #ebeef2;
`

const TagContainer = styled(BaseRow)`
  background-color: #f9fafb;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #ebeef2;
  min-height: 50px;
  margin-inline: 0px !important;
`

const TagItem = styled(BaseRow)`
  border-radius: 20px;
  padding: 2px 8px;
  border: 1px solid #ebeef2;
  cursor: pointer;
  background-color: white;
  margin-inline: 0;
`

const TagOption = styled.div<{ $isSelected: boolean }>`
  background-color: ${props => (props.$isSelected ? '#F2F4F7' : 'white')};
  border-radius: 20px;
  padding: 2px 8px;
  border: 1px solid #ebeef2;
  cursor: pointer;
`

const BaseFormItem = styled(BaseForm.Item)`
  margin-bottom: 0px !important;
`
