import BaseText from 'app/components/common/BaseText'

import {
  EFeedbackChannelMap,
  EFeedbackDepartmentMap,
  EFeedbackLevelTypeMap,
} from 'app/api/operation/general-feedback-customer/contant'
import { ICustomerFeedback } from 'app/api/operation/general-feedback-customer/modal'
import { getDetailPatientUrl } from 'app/common/helpers'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { ActionsDropdown } from 'app/components/tables/BaseTableManagement/ActionDropdown'
import { RenderValueTable } from 'app/components/tables/BaseTableManagement/hook'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { FORMAT_DD_MM_YYYY_HH_MM } from 'app/constant'
import {
  DateUtil,
  FORMAT_DD_MM_YYYY,
  initialPagination,
  Pagination,
} from 'parkway-web-common'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  DisplayNoteComp,
  DynamicContentComp,
  FeedbackTypeItem,
  StatusProcessing
} from './hook'
import { UpdateIdentifiedModal } from './modals'
import { IFormFilter } from './type'

export const useHookIdentified = ({
  params,
  isHavAllPermission,
}: {
  params: IFormFilter
  isHavAllPermission?: boolean
}) => {
  const { t } = useTranslation()
  const [pagination, setPagination] = useState<Pagination>({
    ...initialPagination,
    pageSize: 100,
  })

  const columns = useMemo(() => {
    const option = [
      convertColumnTable<ICustomerFeedback>({
        title: 'Ngày tiếp nhận feedback',
        key: 'receptionAt',
        fixed: 'left',
        className: 'date-time-column',
        render: (_, record) => {
          return (
            <BaseText
              children={DateUtil.formatDate(
                record?.receptionAt ?? '',
                FORMAT_DD_MM_YYYY_HH_MM,
              )}
              fontWeight="medium"
              textAlign="left"
            />
          )
        },
      }),
      convertColumnTable<ICustomerFeedback>({
        title: 'Họ tên KH',
        key: 'patientName',
        fixed: 'left',
        className: 'patient-name-column',
        render: (_, record) => {
          return (
            <RenderValueTable
              value={record?.patientName ?? '-'}
              type="OtherLink"
              hrefOtherLink={getDetailPatientUrl(record.patientId)}
            />
          )
        },
      }),
      convertColumnTable<ICustomerFeedback>({
        title: 'Phòng khám',
        key: 'locationName',
        className: 'clinic-column',
        render: (_, record) => {
          return (
            <BaseText
              children={record?.locationName ?? '-'}
              fontWeight="medium"
              textAlign="left"
            />
          )
        },
      }),

      // kênh feedback
      convertColumnTable<ICustomerFeedback>({
        title: 'Kênh feedback',
        key: 'channel',

        className: 'channel-column',
        render: (_, record) => {
          return (
            <BaseText
              children={
                record?.channel
                  ? EFeedbackChannelMap?.[record?.channel]?.label
                  : ''
              }
              fontWeight="medium"
              textAlign="left"
              opacity="0.7"
            />
          )
        },
      }),

      //Phân loại mức độ
      convertColumnTable<ICustomerFeedback>({
        title: 'Phân loại mức độ',
        key: 'levelTypes',

        className: 'level-type-column',
        render: (_, record) => {
          return record?.levelTypes?.map(item => {
            return (
              <BaseText
                children={EFeedbackLevelTypeMap?.[item]?.label ?? '-'}
                fontWeight="medium"
                textAlign="left"
                opacity="0.7"
              />
            )
          })
        },
      }),

      // Phân loại nội dung
      convertColumnTable<ICustomerFeedback>({
        title: 'Phân loại nội dung',
        key: 'contentTypes',

        className: 'content-type-column',
        render: (_, record) => {
          return (
            <BaseRow gutter={[8, 8]} align="middle">
              {record?.contentTypes?.map(item => {
                return (
                  <BaseCol>
                    <FeedbackTypeItem type={item} />
                  </BaseCol>
                )
              })}
            </BaseRow>
          )
        },
      }),
      // Nội dung feedback
      convertColumnTable<ICustomerFeedback>({
        title: 'Nội dung feedback',
        key: 'dynamicContent',

        className: 'dynamic-content-column',
        render: (_, record) => {
          return (
            <DynamicContentComp
              rate={record?.rate}
              content={record?.dynamicContent ?? ''}
            />
          )
        },
      }),

      // Ghi chú
      convertColumnTable<ICustomerFeedback>({
        title: 'Ghi chú',
        key: 'note',

        className: 'note-column',
        render: (_, record) => {
          return <DisplayNoteComp note={record?.note ?? '-'} />
        },
      }),
      // Bộ phận liên quan
      convertColumnTable<ICustomerFeedback>({
        title: 'Bộ phận liên quan',
        key: 'departments',

        className: 'department-column',
        render: (_, record) => {
          return (
            <BaseRow gutter={[8, 8]} align="middle">
              {record?.departments?.map(item => {
                return (
                  <BaseCol>
                    <div
                      style={{
                        border: '1px solid #E4E7EC',
                        borderRadius: 20,
                        padding: '2px 4px',
                      }}
                    >
                      <BaseText
                        children={EFeedbackDepartmentMap?.[item]?.label ?? '-'}
                        fontWeight="medium"
                        textAlign="left"
                        style={{ color: '#1D2939' }}
                      />
                    </div>
                  </BaseCol>
                )
              })}
            </BaseRow>
          )
        },
      }),
      // Phương án phản hồi KH
      convertColumnTable<ICustomerFeedback>({
        title: 'Phương án phản hồi KH',
        key: 'resolveMethod',

        className: 'resolve-method-column',
        render: (_, record) => {
          return <DisplayNoteComp note={record?.resolveMethod ?? '-'} />
        },
      }),
      // ngày giải quyết
      convertColumnTable<ICustomerFeedback>({
        title: 'Ngày giải quyết',
        key: 'resolveAt',

        className: 'resolve-at-column',
        render: (_, record) => {
          return (
            <BaseText
              children={
                record?.resolveAt
                  ? DateUtil.formatDate(
                      record?.resolveAt ?? '',
                      FORMAT_DD_MM_YYYY,
                    )
                  : '-'
              }
              fontWeight="medium"
              textAlign="left"
            />
          )
        },
      }),
      // kết quả
      convertColumnTable<ICustomerFeedback>({
        title: 'Kết quả',
        key: 'result',

        className: 'result-column',
        render: (_, record) => {
          return <DisplayNoteComp note={record?.result ?? '-'} />
        },
      }),
      // thời gian giải quyết
      convertColumnTable<ICustomerFeedback>({
        title: 'Thời gian giải quyết',
        key: 'resolveDuration',
        className: 'resolve-duration-column',
        render: (_, record) => {
          return (
            <BaseText
              children={(() => {
                if (record?.resolveDuration === 0) {
                  return '0 ngày'
                }
                return record?.resolveDuration
                  ? `${record?.resolveDuration} ngày`
                  : '-'
              })()}
              fontWeight="medium"
              textAlign="right"
              opacity="0.7"
              style={{ color: '#6D7580' }}
            />
          )
        },
      }),
      // trạng thái xử lý
      convertColumnTable<ICustomerFeedback>({
        title: 'Trạng thái xử lý',
        key: 'status',
        className: 'status-column',
        render: (_, record) => {
          return <StatusProcessing status={record?.status} />
        },
      }),

      ...(isHavAllPermission
        ? [
            convertColumnTable<ICustomerFeedback>({
              title: '',
              key: '_id',
              className: 'small-column',
              width: '5%',
              fixed: 'right',
              render: (_, record) => {
                return (
                  <ActionsDropdown
                    actionComponent={
                      <BaseSpace>
                        <UpdateIdentifiedModal record={record} />
                      </BaseSpace>
                    }
                  />
                )
              },
            }),
          ]
        : []),
    ]
    return option
  }, [t, params, isHavAllPermission])

  const handleTableChange = (pagination: Pagination) => {
    setPagination(pagination)
  }

  return {
    columns,
    handleTableChange,
    pagination,
    setPagination,
  }
}
