import { IQueryCustomerFeedback } from 'app/api/operation/general-feedback-customer/modal'
import { ModulePermissionEnum } from 'app/common/permission-module'
import { Dayjs } from 'dayjs'

export interface IDataTable {
  [key: string]: string
}

export enum EGroupFeedback {
  // đã định danh
  IDENTIFIED = 'Đã định danh',
  // chưa định danh
  UNIDENTIFIED = 'Chưa định danh',
}

export interface IFormFilter extends IQueryCustomerFeedback {
  startDateDayJs?: Dayjs | null
  endDateDayJs?: Dayjs | null
  groupFeedback?: EGroupFeedback
}

export enum FeedbackSurveyReport23Enum {
  PROFESSIONAL_COMPETENCE = 'Năng lực chuyên môn',
  SERVICE_EXPERIENCE = 'Trải nghiệm dịch vụ',
  FACILITIES = 'Cơ sở vật chất',
}

export interface IProps {
  module: ModulePermissionEnum
}
