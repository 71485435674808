import R from 'app/assets/R'
import { SvgPlusWhiteIcon } from 'app/assets/svg-assets'
import { BasePopover } from 'app/components/common/BasePopover'
import { BaseSpace } from 'app/components/common/BaseSpace'
import HeaderPage from 'app/components/header/components/HeaderPage'
import { PROFILE_MANAGEMENT_CREATE_PATH } from 'app/components/router/route-path'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import * as S from 'app/components/tables/BaseTableManagement/styles'
import { t } from 'i18next'
import { useNavigate } from 'react-router'
import { ImportFileModal } from './components'
import FilterLayout from './components/Filter'
import { useHookTable } from './hook'
import { AddButton, PopoverButton } from './styles'
import { useGetHeightTable } from 'app/hook'
const ProfileManagementContainer = () => {
  const {
    tableData,
    columns,
    handleTableChange,
    filter,
    handleChangeFilter,
    handleExport,
  } = useHookTable()
  const navigate = useNavigate()

  const handleAddEmployee = () => {
    navigate(PROFILE_MANAGEMENT_CREATE_PATH)
  }

  return (
    <S.BaseManagementWrapper>
      <HeaderPage
        titleI18nKey={R.strings.profile_content_management}
        isExport
        onPressExport={handleExport}
        rightComponent={
          <BasePopover
            placement="bottom"
            content={
              <BaseSpace>
                <PopoverButton onClick={handleAddEmployee}>
                  {t(R.strings.add_new_profile)}
                </PopoverButton>
                <ImportFileModal />
              </BaseSpace>
            }
            trigger="click"
          >
            <AddButton icon={<SvgPlusWhiteIcon />}>
              {t(R.strings.profile_content_add)}
            </AddButton>
          </BasePopover>
        }
      />
      <S.RootWrapper>
        <FilterLayout onChangeFilter={handleChangeFilter} filter={filter} />
        <BaseTableManagement
          columns={columns}
          dataSource={tableData.data}
          pagination={tableData.pagination}
          loading={tableData.isLoading}
          onChange={handleTableChange}
          scroll={{
            x: true,
            y: tableData?.data?.length ? useGetHeightTable(195, 30) : undefined,
          }}
        />
      </S.RootWrapper>
    </S.BaseManagementWrapper>
  )
}
export default ProfileManagementContainer
