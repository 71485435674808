/* eslint-disable @typescript-eslint/no-explicit-any */
import { NOTIFICATION_TYPE } from 'app/common/constants'
import MainLayout from 'app/components/layouts/main/MainLayout'
import RequireAuth from 'app/components/router/require-auth-router'
import { REPORT_023_FULL_PATH } from 'app/components/router/route-path'
import LoginContainer from 'app/containers/Login'
import { notificationController } from 'app/controllers/notification-controller'
import { withLoading } from 'app/hocs/with-loading.hoc'
import OrganizationalChartPage from 'app/page/menu/company/organizational-chart'
import { DashboardPage } from 'app/page/menu/dashboard/dashboard'
import { DashboardBodPage } from 'app/page/menu/dashboard/dashboard-bod'
import { DashboardConsultationPage } from 'app/page/menu/dashboard/dashboard-consultation'
import { DashboardDoctorPage } from 'app/page/menu/dashboard/dashboard-doctor'
import { DashboardNursePage } from 'app/page/menu/dashboard/dashboard-nurse'
import ProfilePage from 'app/page/menu/profile'
import DevelopmentPage from 'app/page/other/development'
import { NotificationKeys } from 'app/react-query/query-key/notification'
import { useAppDispatch, useAppSelector } from 'app/redux/hooks'
import {
  changeMessage,
  changeRefetchNotificationValue,
} from 'app/redux/slices/profileSlice'
import { INotificationResponse, onMessageListener } from 'app/utils/firebase'
import { isEqual } from 'lodash'
import React, { useEffect, useRef } from 'react'
import { useQueryClient } from 'react-query'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { NotFound } from '../common/NotFound'
import { AccountingRouter } from './accounting-router'
import { CustomerCareRouter } from './customer-care-router'
import { DentalTreatmentManagementRouter } from './dental-treatment-router'
import { HumanResourcesRouter } from './human-resources-router'
import { InventoryManagementRouter } from './inventory-management-router'
import { MarketingRoute } from './marketing-router'
import { MyDeskRouter } from './my-desk'
import NotAuth from './not-auth-router'
import { ReportRouter } from './report-router'
import {
  ANNOUNCEMENT_PATH,
  ATTENDANCE_RECORD_PATH,
  AUTH_PATH,
  CLINIC_MANAGEMENT_PATH,
  COMPANY_PATH,
  DASHBOARD_BOD_PATH,
  DASHBOARD_CONSULTATION_PATH,
  DASHBOARD_DOCTOR_PATH,
  DASHBOARD_NURSE_PATH,
  DASHBOARD_PATH,
  FINANCE_PATH,
  INTERNAL_NEWS_PATH,
  LOGIN_PATH,
  LOGOUT_PATH,
  MAILBOX_PATH,
  MEDICAL_EXAMINATION_PATH,
  MY_INCOME_PATH,
  MY_REQUESTS_PATH,
  MY_TASKS_PATH,
  ONBOARDING_PATH,
  ORGANIZATIONAL_CHART_PATH,
  PROFILE_PATH,
  PURCHASING_MANAGEMENT_PATH,
  WORK_SCHEDULE_PATH,
} from './route-path'
import { UtilitiesManagementRouter } from './utilities-router'
import { ReportKeys } from 'app/react-query/query-key/report'
import { OperationRoute } from './operation-router'

const Logout = React.lazy(() => import('./logout-router'))
const AuthLayout = React.lazy(() => import('app/components/layouts/AuthLayout'))

const Dashboard = withLoading(DashboardPage)
const DashboardDoctor = withLoading(DashboardDoctorPage)
const DashboardBod = withLoading(DashboardBodPage)
const DashboardNurse = withLoading(DashboardNursePage)
const DashboardConsultation = withLoading(DashboardConsultationPage)
const DevelopmentDashboard = withLoading(DevelopmentPage)
const OrganizationalChart = withLoading(OrganizationalChartPage)

const AuthLayoutFallback = withLoading(AuthLayout)
const LogoutFallback = withLoading(Logout)

export const AppRouter: React.FC = () => {
  const protectedLayout = (
    <RequireAuth>
      <MainLayout />
    </RequireAuth>
  )

  const authLayout = (
    <NotAuth>
      <AuthLayoutFallback />
    </NotAuth>
  )

  const notificationMessage = useAppSelector(
    state => state.profileSlice,
  )?.message
  const dispatch = useAppDispatch()
  const audioPlayer = useRef<any>(null)

  function playAudio() {
    audioPlayer?.current?.play()
  }

  useEffect(() => {
    const channel = new BroadcastChannel('notifications')
    channel.addEventListener('message', event => {
      const res: INotificationResponse = event?.data as INotificationResponse
      playAudio()
      notificationHandler(res, false)
    })

    return () => channel.close()
  }, [])

  useEffect(() => {
    onMessageListener().then(data => {
      const res: INotificationResponse = data as INotificationResponse
      playAudio()
      notificationHandler(res)
    })
  })

  const query = useQueryClient()

  const notificationHandler = (res: any, showNotification = true) => {
    const newPayload = JSON.parse(res.data?.payload || '{}')
    const profilePayload = JSON.parse(notificationMessage?.payload || '{}')

    switch (res?.data?.type) {
      case NOTIFICATION_TYPE.APPOINTMENT:
        if (showNotification) {
          notificationController.success({
            message: res?.notification?.title,
            description: `${res?.notification?.body}`,
          })
        }

        dispatch(
          changeMessage({
            type: NOTIFICATION_TYPE.APPOINTMENT,
            currentTime: new Date(),
          }),
        )
        break

      case NOTIFICATION_TYPE.EXPORT_DAILY_INVOICE:
      case NOTIFICATION_TYPE.EXPORT_PATIENT_DEBT:
      case NOTIFICATION_TYPE.EXPORT_PATIENT_DEBT_CONTRACT:
        if (!isEqual(profilePayload, newPayload)) {
          dispatch(
            changeMessage({
              type: res.data?.type,
              currentTime: new Date(),
              payload: res.data?.payload,
            }),
          )
        }

        break
      case NOTIFICATION_TYPE.ZNS_SURVEY:
        if (showNotification) {
          notificationController.warning({
            message: res?.notification?.title,
            description: `${res?.notification?.body}`,
            onClick: () => {
              window.location.href = REPORT_023_FULL_PATH
            },
          })
        }
        dispatch(
          changeMessage({
            type: NOTIFICATION_TYPE.ZNS_SURVEY,
            currentTime: new Date(),
          }),
        )

        dispatch(changeRefetchNotificationValue?.(new Date().getTime()))

        query.invalidateQueries({
          queryKey: NotificationKeys.allHistories,
        })

        query.invalidateQueries({
          queryKey: ReportKeys.allFilterReport023s,
        })
        break
      case NOTIFICATION_TYPE.OTHER:
        // do somethings
        // console.log('OTHER')

        break

      default:
        console.log('default')
        break
    }
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path={DASHBOARD_PATH} element={protectedLayout}>
          <Route index element={<Dashboard />} />
          <Route path={DASHBOARD_BOD_PATH} element={<DashboardBod />} />
          <Route path={DASHBOARD_DOCTOR_PATH} element={<DashboardDoctor />} />
          <Route path={DASHBOARD_NURSE_PATH} element={<DashboardNurse />} />
          <Route
            path={DASHBOARD_CONSULTATION_PATH}
            element={<DashboardConsultation />}
          />

          {ReportRouter()}
          {MyDeskRouter()}
          <Route path={WORK_SCHEDULE_PATH} element={<DevelopmentDashboard />} />
          <Route path={PROFILE_PATH} element={<ProfilePage />} />
          <Route
            path={ATTENDANCE_RECORD_PATH}
            element={<DevelopmentDashboard />}
          />
          <Route path={MAILBOX_PATH} element={<DevelopmentDashboard />} />
          <Route path={MY_REQUESTS_PATH} element={<DevelopmentDashboard />} />
          <Route path={MY_TASKS_PATH} element={<DevelopmentDashboard />} />
          <Route path={MY_INCOME_PATH} element={<DevelopmentDashboard />} />
          <Route
            path={MEDICAL_EXAMINATION_PATH}
            element={<DevelopmentDashboard />}
          />
          <Route path={FINANCE_PATH} element={<DevelopmentDashboard />} />
          <Route
            path={PURCHASING_MANAGEMENT_PATH}
            element={<DevelopmentDashboard />}
          />
          <Route
            path={CLINIC_MANAGEMENT_PATH}
            element={<DevelopmentDashboard />}
          />
          <Route path={COMPANY_PATH}>
            <Route
              path={ORGANIZATIONAL_CHART_PATH}
              element={<OrganizationalChart />}
            />
            <Route
              path={ANNOUNCEMENT_PATH}
              element={<DevelopmentDashboard />}
            />
            <Route
              path={INTERNAL_NEWS_PATH}
              element={<DevelopmentDashboard />}
            />
            <Route path={ONBOARDING_PATH} element={<DevelopmentDashboard />} />
          </Route>
          {DentalTreatmentManagementRouter()}
          {HumanResourcesRouter()}
          {InventoryManagementRouter()}
          {MarketingRoute()}
          {CustomerCareRouter()}
          {AccountingRouter()}
          {UtilitiesManagementRouter()}
          {OperationRoute()}
          <Route path="*" element={<NotFound />} />
        </Route>

        <Route path={AUTH_PATH} element={authLayout}>
          <Route path={LOGIN_PATH} element={<LoginContainer />} />
        </Route>
        <Route path={LOGOUT_PATH} element={<LogoutFallback />} />
      </Routes>
    </BrowserRouter>
  )
}
