import {
  FunctionPermissionEnum,
  ModulePermissionEnum,
} from 'app/common/permission-module'
import { ContentLayout } from './content'
import { ReportGeneralFeedbackCustomerProvider } from './context'
import { useVerifyPermissionAndRedirect } from 'app/common/use-verify-permission-redirect'
import { useEffect } from 'react'
import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'

export const ReportGeneralFeedbackCustomerContainer = ({
  module,
}: {
  module: ModulePermissionEnum
}) => {
  const { t } = useTranslation()

  const { isHavePermissionFunctionAndGoBack } = useVerifyPermissionAndRedirect()

  useEffect(() => {
    isHavePermissionFunctionAndGoBack(
      module,
      FunctionPermissionEnum.OPERATION_READ_GENERAL_FEEDBACK_CUSTOMER_REPORT,
      R.strings.report_general_feedback_customer,
    )
  }, [t, module])

  return (
    <ReportGeneralFeedbackCustomerProvider>
      <ContentLayout />
    </ReportGeneralFeedbackCustomerProvider>
  )
}
