
// Endpoint
export const ReportGeneralFeedbackCustomerPoint = {
  GetByYear: 'crm/v1/customer-feedback/chart/year',
  GetByMonth: 'crm/v1/customer-feedback/chart/month',
}

export const ReportGeneralFeedbackCustomerMessageCode = {
  successGetData: '20000',
  successExportData: '20000',
}