import R from 'app/assets/R'
import { SvgTableCloseIcon } from 'app/assets/svg-assets'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { Option } from 'app/components/common/selects/BaseSelect'
import moment from 'moment'
import {
  Dates,
  FORMAT_ONLY_MONTH,
  SelectFilterEnum,
  SelectFilterType,
} from 'parkway-web-common'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as S from './styles'
import { IFilterProps } from './type'

export const FilterLayout = ({
  handleChangeFilter,
  isHaveChooseMonth = true,
  isHaveChooseYear = true,
  selectFilterGroup = 'MONTH_YEAR',
  initSelectEnum = 'YEAR',
  isUsingWeekMonthCorrect = false,
  rootStyle,
}: IFilterProps) => {
  const { t } = useTranslation()
  const [month, setMonth] = useState<string | undefined>(
    `${moment().month() + 1}`,
  )
  const [year, setYear] = useState<string | undefined>(`${moment().year()}`)

  const [selectFilterType, setSelectFilter] =
    useState<SelectFilterType>(initSelectEnum)

  useEffect(() => {
    const timer = setTimeout(() => {
      handleChangeFilter?.({
        month,
        year,
        selectFilterType,
      })
    }, 300)
    return () => clearTimeout(timer)
  }, [selectFilterType, year, month])

  const onChangeMonth = (_, month: string) => {
    setMonth(month)
  }

  const onChangeYear = (_, year: string) => {
    setYear(year)
  }

  const onChangeSelectFilter = (type: SelectFilterType) => {
    setSelectFilter(type)
  }

  const renderFilterMonth = useCallback(() => {
    if (!year) return

    if (isHaveChooseMonth) {
      switch (selectFilterGroup) {
        case 'WEEK_MONTH':
          if (
            selectFilterType === SelectFilterEnum.MONTH &&
            !isUsingWeekMonthCorrect
          )
            return
          break
        case 'MONTH_YEAR':
          if (selectFilterType === SelectFilterEnum.YEAR) return
          break
        case 'MONTH_QUARTER':
          if (selectFilterType === SelectFilterEnum.QUARTER) return
          break
        case 'WEEK_MONTH_QUARTER_YEAR':
          if (selectFilterType === SelectFilterEnum.QUARTER) return
          if (selectFilterType === SelectFilterEnum.YEAR) return
          break
      }

      return (
        <BaseCol xl={5}>
          <BaseForm.Item>
            <S.BaseDatePickerStyled
              picker="month"
              onChange={onChangeMonth}
              format={FORMAT_ONLY_MONTH}
              value={month ? Dates.getDate(`${year}-${month}-01`) : undefined}
              allowClear={false}
              $prefix="Tháng"
              $prefixWidth={80}
            />
          </BaseForm.Item>
        </BaseCol>
      )
    }
  }, [isHaveChooseMonth, selectFilterType, month, year, onChangeMonth])()

  const renderSelectYear = useCallback(() => {
    if (!isHaveChooseYear) return

    switch (selectFilterType) {
      case 'FROM_THEN_UTIL_NOW':
        return
    }

    return (
      <BaseCol xl={5}>
        <BaseForm.Item>
          <S.BaseDatePickerStyled
            picker={'year'}
            onChange={onChangeYear}
            value={Dates.getDate(`${year}-01-01`)}
            allowClear={false}
            $prefix="Năm"
            $prefixWidth={60}
          />
        </BaseForm.Item>
      </BaseCol>
    )
  }, [isHaveChooseYear, selectFilterType, month, year, onChangeYear])()

  return (
    <S.HeadWrapper gutter={16} align={'middle'} style={rootStyle}>
      <BaseCol xl={5}>
        <BaseForm.Item name="select">
          <S.SelectBaseStyled
            defaultValue={selectFilterType}
            value={selectFilterType}
            placeholder={'Select type'}
            suffixIcon={<SvgTableCloseIcon />}
            onChange={type => onChangeSelectFilter(type as SelectFilterType)}
            allowClear={false}
            $prefix="Xem theo"
            $prefixWidth={75}
          >
            <Option value={SelectFilterEnum.MONTH}>{t(R.strings.month)}</Option>
            <Option value={SelectFilterEnum.YEAR}>{t(R.strings.year)}</Option>
          </S.SelectBaseStyled>
        </BaseForm.Item>
      </BaseCol>
      {renderSelectYear}
      {renderFilterMonth}
    </S.HeadWrapper>
  )
}
