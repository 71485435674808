import R from 'app/assets/R'
import { DASHBOARD_PATH } from 'app/components/router/route-path'
import { notificationController } from 'app/controllers/notification-controller'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { FunctionPermissionEnum, ModulePermissionEnum } from './permission-module'
import { useVerifyPermission } from './use-verify-permission'

export const useVerifyPermissionAndRedirect = () => {
  const {
    isHavePermissionFunction,
    isHavePermissionModule,
    userData: user,
  } = useVerifyPermission()

  const navigate = useNavigate()
  const { t } = useTranslation()

  const goBack = () => {
    navigate(DASHBOARD_PATH)
  }

  const onAlertAndGoBack = (keyI18n: string) => {
    notificationController.error({
      message: t(R.strings.you_dont_have_permission, {
        module: t(keyI18n),
      }),
    })
    goBack()
  }

  const isHavePermissionFunctionAndGoBack = (
    moduleName: ModulePermissionEnum,
    permission: FunctionPermissionEnum,
    keyI18nFunction: string,
  ) => {
    const isHavePermission = isHavePermissionFunction(moduleName, permission)

    if (!isHavePermission) {
      onAlertAndGoBack(keyI18nFunction)
    }
  }

  // nhiều function
  const isHavePermissionFunctionAndGoBacks = (
    moduleName: ModulePermissionEnum,
    permissions: FunctionPermissionEnum[],
    keyI18nFunction: string,
  ) => {
    const isHavePermission = permissions.some((permission) =>
      isHavePermissionFunction(moduleName, permission),
    )

    if (!isHavePermission) {
      onAlertAndGoBack(keyI18nFunction)
    }
  }

  const isHavePermissionAndGoBack = (
    moduleName: ModulePermissionEnum,
    keyI18nFunction: string,
  ) => {
    const isHavePermission = isHavePermissionModule(moduleName)

    if (!isHavePermission) {
      onAlertAndGoBack(keyI18nFunction)
    }
  }

  return {
    isHavePermissionModule,
    isHavePermissionFunction,
    isHavePermissionFunctionAndGoBack,
    isHavePermissionAndGoBack,
    user,
    isHavePermissionFunctionAndGoBacks
  }
}
