import { IQueryCustomerFeedback } from 'app/api/operation/general-feedback-customer/modal'

export const GeneralFeedbackCustomerKeys = {
  allGeneralFeedbackCustomer: ['GeneralFeedbackCustomer'] as const,
  listGeneralFeedbackCustomer: () =>
    [
      ...GeneralFeedbackCustomerKeys.allGeneralFeedbackCustomer,
      'listGeneralFeedbackCustomer',
    ] as const,
  getGeneralFeedbackCustomers: (filters?: IQueryCustomerFeedback) =>
    [
      ...GeneralFeedbackCustomerKeys.listGeneralFeedbackCustomer(),
      { filters },
    ] as const,
}
