import { withLoading } from 'app/hocs/with-loading.hoc'
import OperationPage from 'app/page/menu/operation'
import GeneralFeedbackCustomerPage from 'app/page/menu/operation/general-feedback-customer'
import { Route } from 'react-router-dom'
import {
  OPERATION_GENERAL_FEEDBACK_CUSTOMER_PATH,
  OPERATION_PATH,
  OPERATION_REPORT_GENERAL_FEEDBACK_CUSTOMER_PATH,
} from './route-path'
import ReportGeneralFeedbackCustomerPage from 'app/page/menu/operation/report-general-feedback-customer'

const Operation = withLoading(OperationPage)
const OperationGeneralFeedbackCustomer = withLoading(
  GeneralFeedbackCustomerPage,
)
const OperationReportGeneralFeedbackCustomer = withLoading(
  ReportGeneralFeedbackCustomerPage,
)

export const OperationRoute = () => {
  return (
    <>
      <Route path={OPERATION_PATH} element={<Operation />} />
      <Route
        path={OPERATION_GENERAL_FEEDBACK_CUSTOMER_PATH}
        element={<OperationGeneralFeedbackCustomer />}
      />
      <Route
        path={OPERATION_REPORT_GENERAL_FEEDBACK_CUSTOMER_PATH}
        element={<OperationReportGeneralFeedbackCustomer />}
      />
    </>
  )
}
