import { StatusEnum } from 'app/common/enum'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { Loading } from 'app/components/common/Loading'
import { usePagination } from 'app/hook'
import { IUnit } from 'app/model/unit.model'
import { useGetAreaList } from 'app/react-query/hook/location'
import { useMemo } from 'react'
import styled from 'styled-components'
import { StackedBarChart } from './StackedBarChart'

export const MonthLayout = () => {
  const { data, isLoading } = useGetAreaList({
    page: 0,
    pageSize: 1000,
    pagesize: 1000,
    typeid: 'area',
    status: StatusEnum.ACTIVE,
  })

  const { data: dataClinic, isLoading: isLoadingClinic } = useGetAreaList({
    page: 0,
    pageSize: 1000,
    pagesize: 1000,
    typeid: 'clinic',
    status: StatusEnum.ACTIVE,
  })

  const { flattenDataList } = usePagination()

  const areas: IUnit[] = useMemo(() => {
    return flattenDataList(data) ?? []
  }, [data])

  const clinics: IUnit[] = useMemo(() => {
    return flattenDataList(dataClinic) ?? []
  }, [dataClinic])

  return (
    <RootWrapper>
      <BaseRow gutter={[8, 8]} align={'middle'}>
        <BaseCol xl={12}>
          <StackedBarChart
            clinics={clinics}
            isLoadingClinics={isLoadingClinic}
          />
        </BaseCol>
        {areas.map(area => (
          <BaseCol xl={12}>
            <StackedBarChart
              unit={area}
              clinics={clinics}
              isLoadingClinics={isLoadingClinic}
            />
          </BaseCol>
        ))}
      </BaseRow>

      {isLoading && <Loading size="small" isAbsolute />}
    </RootWrapper>
  )
}

export const RootWrapper = styled.div`
  position: relative;
`
