/* eslint-disable @typescript-eslint/no-explicit-any */
import { CloseOutlined, FilterOutlined } from '@ant-design/icons'
import { IParamsGetReport18 } from 'app/api/report/model/report-18'
import R from 'app/assets/R'
import { SvgDownArrowIcon, SvgUpArrowIcon } from 'app/assets/svg-assets'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseDivider } from 'app/components/common/BaseDivider'
import { BasePopover } from 'app/components/common/BasePopover'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { BaseTreeSelect } from 'app/components/common/selects/BaseTreeSelect'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { t } from 'i18next'
import { FONT_SIZE, FONT_WEIGHT } from 'parkway-web-common'
import React, { useState } from 'react'
import styled from 'styled-components'
import { IFormFilter } from '../../../type'
import {
  EFeedbackChannelMap,
  EFeedbackLevelTypeMap,
} from 'app/api/operation/general-feedback-customer/contant'
import { EFeedbackChannel, EFeedbackLevelType } from 'app/api/operation/general-feedback-customer/modal'

interface IProps {
  onChangeFilter?: (value: IFormFilter) => void
  filter?: IFormFilter
}

export const AdvanceFilterLayout: React.FC<IProps> = ({
  filter,
  onChangeFilter,
}) => {
  const [open, setOpen] = useState(false)
  const [filterMore, setFilterMore] = useState<IFormFilter | undefined>(filter)

  const onHide = () => {
    setOpen(false)
  }

  const onPressApply = () => {
    onHide()
    onChangeFilter?.({
      ...filter,
      channels: filterMore?.channels,
      levelTypes: filterMore?.levelTypes,
    })
  }

  const onPressReset = () => {
    setFilterMore({})
    onChangeFilter?.({
      ...filter,
      channels: [],
      levelTypes: [],
    })
  }

  const onChangeLevelTypes = (value: EFeedbackLevelType[]) => {
    setFilterMore({
      ...filterMore,
      levelTypes: value,
    })
  }

  const onChangeChannels = (value: EFeedbackChannel[]) => {
    setFilterMore({
      ...filterMore,
      channels: value,
    })
  }

  return (
    <BasePopoverWrapper
      trigger={'click'}
      placement="bottomRight"
      content={
        <BaseSpace
          split={<BaseDivider style={{ marginBottom: 0, marginTop: 0 }} />}
          size={12}
        >
          <BaseRow gutter={[16, 16]} align={'middle'} justify={'space-between'}>
            <BaseCol>
              <BaseText
                children={'Lọc thêm'}
                fontSize="xs"
                fontWeight="semibold"
              />
            </BaseCol>

            <BaseCol>
              <BaseButton
                icon={<CloseOutlined rev={undefined} />}
                onClick={onHide}
                type="text"
              />
            </BaseCol>
          </BaseRow>

          <BaseForm>
            <BaseSpace size={4}>
              <BaseSpace size={0}>
                <BaseText
                  children={'Phân loại mức độ'}
                  fontWeight="semibold"
                  colorText="main2Color"
                />
                <BaseFormItem hiddenLabel>
                  <BaseSelect
                    placeholder={t(R.strings.all)}
                    width={'100%'}
                    options={Object.values(EFeedbackLevelTypeMap).map(item => ({
                      label: item.label,
                      value: item.value,
                    }))}
                    mode="multiple"
                    maxTagCount={1}
                    allowClear
                    onChange={(val) => onChangeLevelTypes(val as EFeedbackLevelType[])}
                    value={filterMore?.levelTypes}
                  />
                </BaseFormItem>
              </BaseSpace>
            </BaseSpace>
            <BaseSpace size={4}>
              <BaseSpace size={0}>
                <BaseText
                  children={'Kênh feedback'}
                  fontWeight="semibold"
                  colorText="main2Color"
                />
                <BaseFormItem hiddenLabel>
                  <BaseSelect
                    placeholder={t(R.strings.all)}
                    width={'100%'}
                    options={Object.values(EFeedbackChannelMap).map(item => ({
                      label: item.label,
                      value: item.value,
                    }))}
                    mode="multiple"
                    maxTagCount={1}
                    allowClear
                    onChange={(val) => onChangeChannels(val as EFeedbackChannel[])}
                    value={filterMore?.channels}
                  />
                </BaseFormItem>
              </BaseSpace>
            </BaseSpace>
          </BaseForm>

          <BaseRow gutter={[16, 16]} align={'middle'} justify={'space-between'}>
            <BaseCol>
              <BaseButton
                onClick={onPressReset}
                children={t(R.strings.report_24_content_reset)}
              />
            </BaseCol>

            <BaseCol>
              <BaseButton
                onClick={onPressApply}
                type="primary"
                children={t(R.strings.report_24_content_apply)}
              />
            </BaseCol>
          </BaseRow>
        </BaseSpace>
      }
      open={open}
      onOpenChange={setOpen}
    >
      <ButtonOpen
        children={
          <BaseRow align={'middle'} justify={'center'} gutter={[8, 4]}>
            <BaseCol>
              <FilterOutlined rev={undefined} />
            </BaseCol>
            <BaseCol>
              <BaseText
                children={'Lọc thêm'}
                fontWeight="medium"
                fontSize="xs"
                opacity="0.7"
              />
            </BaseCol>
            {!open ? (
              <SvgDownArrowIcon style={{ width: 20, height: 20 }} />
            ) : (
              <SvgUpArrowIcon style={{ width: 20, height: 20 }} />
            )}
          </BaseRow>
        }
      />
    </BasePopoverWrapper>
  )
}

const ButtonOpen = styled(BaseButton)`
  border-color: #f0f1f3;

  &:hover {
    .anticon {
      color: ${convertedVariables.primaryColor};
      opacity: 0.7;
    }
  }
`

const BasePopoverWrapper = styled(BasePopover)``

const BaseTreeSelectWrapper = styled(BaseTreeSelect)`
  width: 100%;
  .ant-select-selector::before {
    font-size: ${FONT_SIZE.xs};
    font-weight: ${FONT_WEIGHT.medium};
    color: ${convertedVariables.primaryColor};
    opacity: 0.4;
    align-items: center;
    display: flex;
    padding-left: 4px;
  }
`

const BaseFormItem = styled(BaseForm.Item)`
  margin-bottom: 0px !important;
`
