import BaseText from 'app/components/common/BaseText'

import { ICustomerFeedback } from 'app/api/operation/general-feedback-customer/modal'
import { SvgStarWhiteIcon } from 'app/assets/svg-assets'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { ActionsDropdown } from 'app/components/tables/BaseTableManagement/ActionDropdown'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { FORMAT_DD_MM_YYYY_HH_MM } from 'app/constant'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { DateUtil, initialPagination, Pagination } from 'parkway-web-common'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  DisplayNoteComp,
  DynamicContentComp,
  geColorFeedback,
  StarWrapper,
} from './hook'
import { UpdateUnidentifiedModal } from './modals'
import { IFormFilter } from './type'

export const useHookUnidentified = ({ params }: { params: IFormFilter }) => {
  const { t } = useTranslation()
  const [pagination, setPagination] = useState<Pagination>({
    ...initialPagination,
    pageSize: 100,
  })

  const columns = useMemo(() => {
    const option = [
      convertColumnTable<ICustomerFeedback>({
        title: 'Ngày tiếp nhận feedback',
        key: 'receptionAt',
        fixed: 'left',
        className: 'date-time-column',
        width: '10%',
        render: (_, record) => {
          return (
            <BaseText
              children={DateUtil.formatDate(
                record?.receptionAt ?? '',
                FORMAT_DD_MM_YYYY_HH_MM,
              )}
              fontWeight="medium"
              textAlign="left"
            />
          )
        },
      }),
      convertColumnTable<ICustomerFeedback>({
        title: 'Phòng khám',
        key: 'locationName',
        className: 'clinic-column',
        width: '10%',
        render: (_, record) => {
          return (
            <BaseText
              children={record?.locationName ?? '-'}
              fontWeight="medium"
              textAlign="left"
            />
          )
        },
      }),
      convertColumnTable<ICustomerFeedback>({
        title: 'Nội dung feedback',
        key: 'dynamicContent',
        classNameWidthColumnOverwrite: 'very-big-column',
        render: (_, record) => {
          return (
            <DynamicContentComp
              rate={record?.rate}
              content={record?.dynamicContent ?? ''}
            />
          )
        },
      }),
      convertColumnTable<ICustomerFeedback>({
        title: '',
        key: '_id',
        className: 'small-column',
        width: '2%',
        fixed: 'right',
        render: (_, record) => {
          return (
            <ActionsDropdown
              actionComponent={
                <BaseSpace>
                  <UpdateUnidentifiedModal record={record} />
                </BaseSpace>
              }
            />
          )
        },
      }),
    ]
    return option
  }, [t, params])

  const handleTableChange = (pagination: Pagination) => {
    setPagination(pagination)
  }

  return {
    columns,
    handleTableChange,
    pagination,
    setPagination,
  }
}
