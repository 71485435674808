import R from 'app/assets/R'
import {
  SvgAccountingFocusIcon,
  SvgAccountingIcon,
  SvgAttendanceRecordFocusIcon,
  SvgAttendanceRecordIcon,
  SvgClinicManagementFocusIcon,
  SvgClinicManagementIcon,
  SvgCompanyFocusIcon,
  SvgCompanyIcon,
  SvgCustomerCareFocusIcon,
  SvgCustomerCareIcon,
  SvgDashboardFocusIcon,
  SvgDashboardIcon,
  SvgDocumentFocusIcon,
  SvgDocumentIcon,
  SvgHumanResourcesFocusIcon,
  SvgHumanResourcesIcon,
  SvgInventoryManagementFocusIcon,
  SvgInventoryManagementIcon,
  SvgMailBoxFocusIcon,
  SvgMailBoxIcon,
  SvgMarketingFocusIcon,
  SvgMarketingIcon,
  SvgMedicalExaminationFocusIcon,
  SvgMedicalExaminationIcon,
  SvgMyDeskFocusIcon,
  SvgMyDeskIcon,
  SvgMyIncomeFocusIcon,
  SvgMyIncomeIcon,
  SvgMyRequestFocusIcon,
  SvgMyRequestIcon,
  SvgMyTaskIcon,
  SvgMyTasksFocusIcon,
  SvgPurchasingManagementFocusIcon,
  SvgPurchasingManagementIcon,
  SvgReportFocusIcon,
  SvgReportIcon,
  SvgUtilitiesFocusIcon,
  SvgUtilitiesIcon,
  SvgWorkScheduleFocusIcon,
  SvgWorkScheduleIcon,
} from 'app/assets/svg-assets'
import {
  FunctionPermissionEnum,
  ModulePermissionEnum,
} from 'app/common/permission-module'
import {
  ACCOUNTING_PATH,
  ATTENDANCE_RECORD_PATH,
  CLINIC_MANAGEMENT_PATH,
  COMPANY_ANNOUNCEMENT_PATH,
  COMPANY_INTERNAL_NEWS_PATH,
  COMPANY_ONBOARDING_PATH,
  COMPANY_ORGANIZATIONAL_CHART_PATH,
  CUSTOMER_CARE_PATH,
  DASHBOARD_PATH,
  DENTAL_TREATMENT_MANAGEMENT_PATH,
  HUMAN_RESOURCES_PATH,
  INVENTORY_MANAGEMENT_PATH,
  MAILBOX_PATH,
  MARKETING_PATH,
  MY_INCOME_PATH,
  MY_REQUESTS_PATH,
  MY_TASKS_PATH,
  OPERATION_PATH,
  PROMOTION_ON_GOING_PATH,
  PURCHASING_MANAGEMENT_PATH,
  REPORT_PATH,
  UTILITIES_PATH,
  WORK_SCHEDULE_PATH,
} from 'app/components/router/route-path'
import React from 'react'

export interface SidebarNavigationItem {
  title: string
  key: string
  url?: string
  children?: SidebarNavigationItem[]
  icon?: React.ReactNode
  iconFocus?: React.ReactNode
  modulePermission?: ModulePermissionEnum
  moduleFunctionPermission?: FunctionPermissionEnum

  isLink?: boolean
}

export const sidebarNavigation: SidebarNavigationItem[] = [
  {
    title: R.strings.dashboard,
    key: R.strings.dashboard,
    url: DASHBOARD_PATH,
    icon: <SvgDashboardIcon />,
    iconFocus: <SvgDashboardFocusIcon />,
  },
  {
    title: R.strings.attendance_record,
    key: R.strings.attendance_record,
    url: ATTENDANCE_RECORD_PATH,
    icon: <SvgAttendanceRecordIcon />,
    iconFocus: <SvgAttendanceRecordFocusIcon />,
    modulePermission: ModulePermissionEnum.ATTENDANCE,
  },
  {
    title: R.strings.work_schedule,
    key: R.strings.work_schedule,
    url: WORK_SCHEDULE_PATH,
    icon: <SvgWorkScheduleIcon />,
    iconFocus: <SvgWorkScheduleFocusIcon />,
    modulePermission: ModulePermissionEnum.WORK_SCHEDULE,
  },
  {
    title: R.strings.mailbox,
    key: R.strings.mailbox,
    url: MAILBOX_PATH,
    icon: <SvgMailBoxIcon />,
    iconFocus: <SvgMailBoxFocusIcon />,
    modulePermission: ModulePermissionEnum.MAIL_BOX,
  },
  {
    title: R.strings.my_desk_en,
    key: R.strings.my_desk_en,
    icon: <SvgMyDeskIcon />,
    iconFocus: <SvgMyDeskFocusIcon />,
    children: [
      {
        title: R.strings.promotion_ongoing,
        key: R.strings.promotion_ongoing,
        url: PROMOTION_ON_GOING_PATH,
      },
    ],
  },
  {
    title: R.strings.document_1,
    key: R.strings.document_1,
    icon: <SvgDocumentIcon />,
    iconFocus: <SvgDocumentFocusIcon />,
    children: [
      {
        title: R.strings.orthodontic,
        key: R.strings.orthodontic,
        isLink: true,
      },
      {
        title: R.strings.implant,
        key: R.strings.implant,
        isLink: true,
      },
      {
        title: R.strings.general_dentistry,
        key: R.strings.general_dentistry,
        isLink: true,
      },
      {
        title: R.strings.cosmetic_dentistry,
        key: R.strings.cosmetic_dentistry,
        isLink: true,
      },
      {
        title: R.strings.pediatric_dentistry,
        key: R.strings.pediatric_dentistry,
        isLink: true,
      },
      {
        title: R.strings.form_form,
        key: R.strings.form_form,
        isLink: true,
      },
    ],
  },
  {
    title: R.strings.my_requests,
    key: R.strings.my_requests,
    url: MY_REQUESTS_PATH,
    icon: <SvgMyRequestIcon />,
    iconFocus: <SvgMyRequestFocusIcon />,
    modulePermission: ModulePermissionEnum.REQUESTS,
  },
  {
    title: R.strings.my_tasks,
    key: R.strings.my_tasks,
    url: MY_TASKS_PATH,
    icon: <SvgMyTaskIcon />,
    iconFocus: <SvgMyTasksFocusIcon />,
    modulePermission: ModulePermissionEnum.TASK,
  },
  {
    title: R.strings.my_income,
    key: R.strings.my_income,
    url: MY_INCOME_PATH,
    icon: <SvgMyIncomeIcon />,
    iconFocus: <SvgMyIncomeFocusIcon />,
    modulePermission: ModulePermissionEnum.INCOME,
  },
  {
    title: R.strings.dental_treatment_management,
    key: R.strings.dental_treatment_management,
    url: DENTAL_TREATMENT_MANAGEMENT_PATH,
    icon: <SvgMedicalExaminationIcon />,
    iconFocus: <SvgMedicalExaminationFocusIcon />,
  },
  {
    title: R.strings.hr,
    key: R.strings.hr,
    url: HUMAN_RESOURCES_PATH,
    icon: <SvgHumanResourcesIcon />,
    iconFocus: <SvgHumanResourcesFocusIcon />,
    modulePermission: ModulePermissionEnum.HR,
  },
  {
    title: R.strings.inventory_management,
    key: R.strings.inventory_management,
    url: INVENTORY_MANAGEMENT_PATH,
    icon: <SvgInventoryManagementIcon />,
    iconFocus: <SvgInventoryManagementFocusIcon />,
    modulePermission: ModulePermissionEnum.INVENTORY,
  },
  {
    title: R.strings.purchasing_management,
    key: R.strings.purchasing_management,
    url: PURCHASING_MANAGEMENT_PATH,
    icon: <SvgPurchasingManagementIcon />,
    iconFocus: <SvgPurchasingManagementFocusIcon />,
    modulePermission: ModulePermissionEnum.PURCHASING,
  },
  {
    title: R.strings.customer_care,
    key: R.strings.customer_care,
    url: CUSTOMER_CARE_PATH,
    icon: <SvgCustomerCareIcon />,
    iconFocus: <SvgCustomerCareFocusIcon />,
    modulePermission: ModulePermissionEnum.CUSTOMER_CARE,
  },
  {
    title: R.strings.clinic_management,
    key: R.strings.clinic_management,
    url: CLINIC_MANAGEMENT_PATH,
    icon: <SvgClinicManagementIcon />,
    iconFocus: <SvgClinicManagementFocusIcon />,
    modulePermission: ModulePermissionEnum.CLINIC_MANAGEMENT,
  },
  {
    title: R.strings.company,
    key: R.strings.company,
    icon: <SvgCompanyIcon />,
    iconFocus: <SvgCompanyFocusIcon />,
    children: [
      {
        title: R.strings.organizational_chart,
        key: R.strings.organizational_chart,
        url: COMPANY_ORGANIZATIONAL_CHART_PATH,
      },
      {
        title: R.strings.announcement,
        key: R.strings.announcement,
        url: COMPANY_ANNOUNCEMENT_PATH,
      },
      {
        title: R.strings.internal_news,
        key: R.strings.internal_news,
        url: COMPANY_INTERNAL_NEWS_PATH,
      },
      {
        title: R.strings.onboarding,
        key: R.strings.onboarding,
        url: COMPANY_ONBOARDING_PATH,
      },
      {
        title: R.strings.policy,
        key: R.strings.policy,
        isLink: true,
      },
      {
        title: R.strings.job_lead,
        key: R.strings.job_lead,
        isLink: true,
      },
    ],
  },
  {
    title: R.strings.report,
    key: R.strings.report,
    url: REPORT_PATH,
    icon: <SvgReportIcon />,
    iconFocus: <SvgReportFocusIcon />,
    modulePermission: ModulePermissionEnum.REPORT,
  },
  {
    title: R.strings.marketing,
    key: R.strings.marketing,
    url: MARKETING_PATH,
    icon: <SvgMarketingIcon />,
    iconFocus: <SvgMarketingFocusIcon />,
    modulePermission: ModulePermissionEnum.MARKETING,
  },
  {
    title: R.strings.operation,
    key: R.strings.operation,
    url: OPERATION_PATH,
    icon: <SvgMarketingIcon />,
    iconFocus: <SvgMarketingFocusIcon />,
    modulePermission: ModulePermissionEnum.OPERATION,
  },
  {
    title: R.strings.accounting,
    key: R.strings.accounting,
    url: ACCOUNTING_PATH,
    icon: <SvgAccountingIcon />,
    iconFocus: <SvgAccountingFocusIcon />,
    modulePermission: ModulePermissionEnum.ACCOUNTING,
  },
  {
    title: R.strings.utilities,
    key: R.strings.utilities,
    url: UTILITIES_PATH,
    icon: <SvgUtilitiesIcon />,
    iconFocus: <SvgUtilitiesFocusIcon />,
  },
]
