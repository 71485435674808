import { memo } from 'react'
import { isEqual } from 'lodash'
import { PageTitle } from 'app/components/common/PageTitle'
import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'
import { GeneralFeedbackCustomerContainer } from 'app/containers/Operation/GeneralFeedbackCustomer'
import { ModulePermissionEnum } from 'app/common/permission-module'

const GeneralFeedbackCustomerPageCpn = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>{t(R.strings.general_feedback_customer)}</PageTitle>
      <GeneralFeedbackCustomerContainer module={ModulePermissionEnum.OPERATION} />
    </>
  )
}
const GeneralFeedbackCustomerPage = memo(
  GeneralFeedbackCustomerPageCpn,
  isEqual,
)

export default GeneralFeedbackCustomerPage
